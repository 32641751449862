import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Modal,
	Card,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { withAuthenticator } from './NoMembers';
import Header from '../@Include/Header';
import SiteFlag from '../@Include/SiteFlag';
import Footer from '../@Include/Footer';
import CardInput from '../@Include/StripeCardInput';


class Default extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading       : true,
			isValidated     : false,
			isLockForm      : false,
			isLastPage      : false,
			isODate         : false,
			isAddressSelect : false,
			isCardSelect    : false,
			ErrMsg          : '',
			orderno         : '',
			flag            : SiteFlag.order,
			cognitoid       : '',
			email           : '',
			name_1          : '',
			name_2          : '',
			name_furi_1     : '',
			name_furi_2     : '',
			zip_1           : '',
			zip_2           : '',
			pref            : '',
			addr_1          : '',
			addr_2          : '',
			addr_3          : '',
			tel_1           : '',
			tel_2           : '',
			tel_3           : '',
			addressid       : '',
			o_name_1        : '',
			o_name_2        : '',
			o_name_furi_1   : '',
			o_name_furi_2   : '',
			o_zip_1         : '',
			o_zip_2         : '',
			o_pref          : '',
			o_addr_1        : '',
			o_addr_2        : '',
			o_addr_3        : '',
			o_tel_1         : '',
			o_tel_2         : '',
			o_tel_3         : '',
			o_com_name      : '',
			total_price     : 0,
			payment_type    : '',
			payment_price   : 0,
			carriage_type   : '',
			carriage        : 0,
			wrapping_type   : '',
			wrapping        : 0,
			coupon_type     : '',
			coupon          : 0,
			cashdeli        : 0,
			o_date_type     : '',
			o_date_h        : '',
			card_id         : '',
			carriage_free_result            : false,
			carriage_free_flag              : false,
			carriage_free_price             : 0,
			carriage_free_ec_user_only_flag : false,
			carriage_free_order_history_kbn : '',
			carriage_1      : 0,
			carriage_2      : 0,
			carriage_3      : 0,
			cashdeli_1      : 0,
			cashdeli_2      : 0,
			wrapping_1      : 0,
			date_diff       : 0,
			startDate       : '',
			endDate         : '',
			order_memo      : '',
			cart_err_items  : [],
		}
		this._backTop             = this._backTop.bind(this);
		this._selectAddress       = this._selectAddress.bind(this);
		this._selectCard          = this._selectCard.bind(this);
		this._getData             = this._getData.bind(this);
		this.setAddress           = this.setAddress.bind(this);
		this.selectCarriage       = this.selectCarriage.bind(this);
		this.selectPayment        = this.selectPayment.bind(this);
		this.selectODate          = this.selectODate.bind(this);
		this.selectODateH         = this.selectODateH.bind(this);
		this.selectWrapping       = this.selectWrapping.bind(this);
		this.selectCoupon         = this.selectCoupon.bind(this);
		this.changeOrderMemo      = this.changeOrderMemo.bind(this);
		this.updateOrderMemo      = this.updateOrderMemo.bind(this);
		this.calcPaymentPrice     = this.calcPaymentPrice.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
		this.handleSubmit         = this.handleSubmit.bind(this);
		this.handleBackPage       = this.handleBackPage.bind(this);
	}
	_backTop() {
		//子ウィンドウからの呼び出し用
		this.handleReload();
	}
	_selectAddress(addressid, o_name_1, o_name_2, o_name_furi_1, o_name_furi_2, o_zip_1, o_zip_2, o_pref, o_addr_1, o_addr_2, o_addr_3, o_tel_1, o_tel_2, o_tel_3, o_com_name) {

		//アドレスの設定
		this.setAddress(addressid, o_name_1, o_name_2, o_name_furi_1, o_name_furi_2, o_zip_1, o_zip_2, o_pref, o_addr_1, o_addr_2, o_addr_3, o_tel_1, o_tel_2, o_tel_3, o_com_name);

		//子ウィンドウからの呼び出し用
		this.handleReload();
	}
	async _selectCard(card_id,card_brand,last_4,exp_month,exp_year,cardholder_name,fingerprint,use_flag) {

		//カード選択状態にする
		this.selectPayment('クレジットカード決済', 0, card_id);

		//子ウィンドウからの呼び出し用
		this.handleReload();
	}
	async _getData() {

		//ログインの確認
		try{
			//Cognito IDの取得
			const user         = await API.Auth.currentAuthenticatedUser();
			const cognitoid    = user.username;

			//ログイン済みの場合フラグ
			if(cognitoid){
				this.setState({
					cognitoid  : cognitoid,
				 });
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			//読み込み完了
			this.setState({
				isLoading  : false,
				ErrMsg     : err_msg + '<br/>',
			 });
			return(0);
		}

		//カートデータの確認
		try{

			const ref = await API.Cart.Check();

			//エラー処理
			if(ref.error){
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}

			if(ref.items.length === 0){
				//カートにデータがない場合
				this.props.history.push('/cart/');
			}


		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLoading  : false,
			 });
			return(0);
		}

		try{
			//送料取得
			const refSetting = await API.Setting.Get();

			//送料無料設定
			const carriage_free_flag              = refSetting.item.carriage_free_flag;
			const carriage_free_price             = refSetting.item.carriage_free_price;
			const carriage_free_ec_user_only_flag = refSetting.item.carriage_free_ec_user_only_flag;
			const carriage_free_order_history_kbn = refSetting.item.carriage_free_order_history_kbn;

			//メール便送料
			const carriage_1 = refSetting.item.carriage_1;

			//宅急便送料
			const carriage_2 = refSetting.item.carriage_2;

			//宅急便送料（北海道・沖縄県）
			const carriage_3 = refSetting.item.carriage_3;

			//代引き手数料
			const cashdeli_1 = refSetting.item.cashdeli_1;

			//後払い手数料
			const cashdeli_2 = refSetting.item.cashdeli_2;

			//ラッピング手数料
			const wrapping_1 = refSetting.item.wrapping_1;

			//配達希望日の可能期間
			const date_diff  = refSetting.item.date_diff;

			//各項目宣言
			let isODate       = this.state.isODate;
			let email         = '';
			let name_1        = '';
			let name_2        = '';
			let name_furi_1   = '';
			let name_furi_2   = '';
			let zip_1         = '';
			let zip_2         = '';
			let pref          = '';
			let addr_1        = '';
			let addr_2        = '';
			let addr_3        = '';
			let tel_1         = '';
			let tel_2         = '';
			let tel_3         = '';
			let addressid     = '';
			let o_name_1      = '';
			let o_name_2      = '';
			let o_name_furi_1 = '';
			let o_name_furi_2 = '';
			let o_zip_1       = '';
			let o_zip_2       = '';
			let o_pref        = '';
			let o_addr_1      = '';
			let o_addr_2      = '';
			let o_addr_3      = '';
			let o_tel_1       = '';
			let o_tel_2       = '';
			let o_tel_3       = '';
			let o_com_name    = '';
			let payment_type  = '';
			let carriage_type = '';
			let wrapping_type = 'しない';
			let coupon_type   = '';
			let o_date_h      = '指定なし';
			let o_date_type   = '';
			let order_memo    = '';
			let card_id       = '';
			let card_name     = '';

			//仮受注データの確認
			const refPreOerder = await API.PreOrder.Get(this.state.flag);

			//データがない場合
			if(refPreOerder.item === null){

				//会員データを取得
				const refProfile = await API.Profile.Get();
				//console.log(refProfile);

				//データがない場合
				if(refProfile.item === null){
					this.setState({
						isLoading  : false,
						ErrMsg     : '※会員データが存在しません。<br/>',
					 });
					return(0);
				}

				//各項目取得
				email         = refProfile.item.email             ? refProfile.item.email         :  '';
				name_1        = refProfile.item.name_1            ? refProfile.item.name_1        :  '';
				name_2        = refProfile.item.name_2            ? refProfile.item.name_2        :  '';
				name_furi_1   = refProfile.item.name_furi_1       ? refProfile.item.name_furi_1   :  '';
				name_furi_2   = refProfile.item.name_furi_2       ? refProfile.item.name_furi_2   :  '';
				zip_1         = refProfile.item.zip_1             ? refProfile.item.zip_1         :  '';
				zip_2         = refProfile.item.zip_2             ? refProfile.item.zip_2         :  '';
				pref          = refProfile.item.pref              ? refProfile.item.pref          :  '';
				addr_1        = refProfile.item.addr_1            ? refProfile.item.addr_1        :  '';
				addr_2        = refProfile.item.addr_2            ? refProfile.item.addr_2        :  '';
				addr_3        = refProfile.item.addr_3            ? refProfile.item.addr_3        :  '';
				tel_1         = refProfile.item.tel_1             ? refProfile.item.tel_1         :  '';
				tel_2         = refProfile.item.tel_2             ? refProfile.item.tel_2         :  '';
				tel_3         = refProfile.item.tel_3             ? refProfile.item.tel_3         :  '';

				//アドレスデータを取得
				const refAddress = await API.Address.GetAll();

				//データがある場合
				if(refAddress.items !== null){

					//デフォルト使用検索
					const viewData = refAddress.items.filter(item => item.use_flag === true );

					//デフォルト使用があれば
					if(viewData.length !== 0){
						addressid     = viewData[0].addressid         ? viewData[0].addressid     :  '';
						o_name_1      = viewData[0].o_name_1          ? viewData[0].o_name_1      :  '';
						o_name_2      = viewData[0].o_name_2          ? viewData[0].o_name_2      :  '';
						o_name_furi_1 = viewData[0].o_name_furi_1     ? viewData[0].o_name_furi_1 :  '';
						o_name_furi_2 = viewData[0].o_name_furi_2     ? viewData[0].o_name_furi_2 :  '';
						o_zip_1       = viewData[0].o_zip_1           ? viewData[0].o_zip_1       :  '';
						o_zip_2       = viewData[0].o_zip_2           ? viewData[0].o_zip_2       :  '';
						o_pref        = viewData[0].o_pref            ? viewData[0].o_pref        :  '';
						o_addr_1      = viewData[0].o_addr_1          ? viewData[0].o_addr_1      :  '';
						o_addr_2      = viewData[0].o_addr_2          ? viewData[0].o_addr_2      :  '';
						o_addr_3      = viewData[0].o_addr_3          ? viewData[0].o_addr_3      :  '';
						o_tel_1       = viewData[0].o_tel_1           ? viewData[0].o_tel_1       :  '';
						o_tel_2       = viewData[0].o_tel_2           ? viewData[0].o_tel_2       :  '';
						o_tel_3       = viewData[0].o_tel_3           ? viewData[0].o_tel_3       :  '';
						o_com_name    = viewData[0].o_com_name        ? viewData[0].o_com_name    :  '';

					}
					else{
						//ソート
						refAddress.items.sort(
							(a, b) => {
								if (a.orderno < b.orderno) return -1;
								if (a.orderno > b.orderno) return 1;
								if (a.date_edit < b.date_edit) return -1;
								if (a.date_edit > b.date_edit) return 1;
								return 0;
							}
						);


						//一番若い方を
						addressid     = refAddress.items[0].addressid         ? refAddress.items[0].addressid     :  '';
						o_name_1      = refAddress.items[0].o_name_1          ? refAddress.items[0].o_name_1      :  '';
						o_name_2      = refAddress.items[0].o_name_2          ? refAddress.items[0].o_name_2      :  '';
						o_name_furi_1 = refAddress.items[0].o_name_furi_1     ? refAddress.items[0].o_name_furi_1 :  '';
						o_name_furi_2 = refAddress.items[0].o_name_furi_2     ? refAddress.items[0].o_name_furi_2 :  '';
						o_zip_1       = refAddress.items[0].o_zip_1           ? refAddress.items[0].o_zip_1       :  '';
						o_zip_2       = refAddress.items[0].o_zip_2           ? refAddress.items[0].o_zip_2       :  '';
						o_pref        = refAddress.items[0].o_pref            ? refAddress.items[0].o_pref        :  '';
						o_addr_1      = refAddress.items[0].o_addr_1          ? refAddress.items[0].o_addr_1      :  '';
						o_addr_2      = refAddress.items[0].o_addr_2          ? refAddress.items[0].o_addr_2      :  '';
						o_addr_3      = refAddress.items[0].o_addr_3          ? refAddress.items[0].o_addr_3      :  '';
						o_tel_1       = refAddress.items[0].o_tel_1           ? refAddress.items[0].o_tel_1       :  '';
						o_tel_2       = refAddress.items[0].o_tel_2           ? refAddress.items[0].o_tel_2       :  '';
						o_tel_3       = refAddress.items[0].o_tel_3           ? refAddress.items[0].o_tel_3       :  '';
						o_com_name    = refAddress.items[0].o_com_name        ? refAddress.items[0].o_com_name    :  '';
					}
				}

				//通常使うクレジットカード情報
				const refCard = await API.StripeCard.GetAll();
				if(refCard.items){
					for(const item of refCard.items){
						const use_flag = item.use_flag ? true : false;
						if(use_flag){
							card_id   = item.card_id;
							card_name = item.card_brand + ' ' + item.last_4;
							break;
						}
					}
				}

				//データを作る
				const orderData = {
					flag          : this.state.flag                     ,
					isODate       : isODate       ? isODate       : null,
					email         : email         ? email         : null,
					name_1        : name_1        ? name_1        : null,
					name_2        : name_2        ? name_2        : null,
					name_furi_1   : name_furi_1   ? name_furi_1   : null,
					name_furi_2   : name_furi_2   ? name_furi_2   : null,
					zip_1         : zip_1         ? zip_1         : null,
					zip_2         : zip_2         ? zip_2         : null,
					pref          : pref          ? pref          : null,
					addr_1        : addr_1        ? addr_1        : null,
					addr_2        : addr_2        ? addr_2        : null,
					addr_3        : addr_3        ? addr_3        : null,
					tel_1         : tel_1         ? tel_1         : null,
					tel_2         : tel_2         ? tel_2         : null,
					tel_3         : tel_3         ? tel_3         : null,
					addressid     : addressid     ? addressid     : null,
					o_name_1      : o_name_1      ? o_name_1      : null,
					o_name_2      : o_name_2      ? o_name_2      : null,
					o_name_furi_1 : o_name_furi_1 ? o_name_furi_1 : null,
					o_name_furi_2 : o_name_furi_2 ? o_name_furi_2 : null,
					o_zip_1       : o_zip_1       ? o_zip_1       : null,
					o_zip_2       : o_zip_2       ? o_zip_2       : null,
					o_pref        : o_pref        ? o_pref        : null,
					o_addr_1      : o_addr_1      ? o_addr_1      : null,
					o_addr_2      : o_addr_2      ? o_addr_2      : null,
					o_addr_3      : o_addr_3      ? o_addr_3      : null,
					o_tel_1       : o_tel_1       ? o_tel_1       : null,
					o_tel_2       : o_tel_2       ? o_tel_2       : null,
					o_tel_3       : o_tel_3       ? o_tel_3       : null,
					o_com_name    : o_com_name    ? o_com_name    : null,
					card_id       : card_id       ? card_id       : null,
					card_name     : card_name     ? card_name     : null,
				};

				//仮受注データに入れる
				const ref = await API.PreOrder.Add(orderData);

				if(ref.error !== null){
					//エラー処理
					const error = ref.error;
					let err_msg = '';
					if(error.errors){
						error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
					}
					else{
						err_msg = error + '<br/>';
					}
					this.setState({
						ErrMsg     : err_msg + '<br/>',
						isLoading  : false,
					});
					return(0);
				}
			}
			else{

				//各項目取得
				isODate       = refPreOerder.item.isODate  === null ? this.state.isODate              : refPreOerder.item.isODate;
				email         = refPreOerder.item.email             ? refPreOerder.item.email         :  '';
				name_1        = refPreOerder.item.name_1            ? refPreOerder.item.name_1        :  '';
				name_2        = refPreOerder.item.name_2            ? refPreOerder.item.name_2        :  '';
				name_furi_1   = refPreOerder.item.name_furi_1       ? refPreOerder.item.name_furi_1   :  '';
				name_furi_2   = refPreOerder.item.name_furi_2       ? refPreOerder.item.name_furi_2   :  '';
				zip_1         = refPreOerder.item.zip_1             ? refPreOerder.item.zip_1         :  '';
				zip_2         = refPreOerder.item.zip_2             ? refPreOerder.item.zip_2         :  '';
				pref          = refPreOerder.item.pref              ? refPreOerder.item.pref          :  '';
				addr_1        = refPreOerder.item.addr_1            ? refPreOerder.item.addr_1        :  '';
				addr_2        = refPreOerder.item.addr_2            ? refPreOerder.item.addr_2        :  '';
				addr_3        = refPreOerder.item.addr_3            ? refPreOerder.item.addr_3        :  '';
				tel_1         = refPreOerder.item.tel_1             ? refPreOerder.item.tel_1         :  '';
				tel_2         = refPreOerder.item.tel_2             ? refPreOerder.item.tel_2         :  '';
				tel_3         = refPreOerder.item.tel_3             ? refPreOerder.item.tel_3         :  '';
				addressid     = refPreOerder.item.addressid         ? refPreOerder.item.addressid     :  '';
				o_name_1      = refPreOerder.item.o_name_1          ? refPreOerder.item.o_name_1      :  '';
				o_name_2      = refPreOerder.item.o_name_2          ? refPreOerder.item.o_name_2      :  '';
				o_name_furi_1 = refPreOerder.item.o_name_furi_1     ? refPreOerder.item.o_name_furi_1 :  '';
				o_name_furi_2 = refPreOerder.item.o_name_furi_2     ? refPreOerder.item.o_name_furi_2 :  '';
				o_zip_1       = refPreOerder.item.o_zip_1           ? refPreOerder.item.o_zip_1       :  '';
				o_zip_2       = refPreOerder.item.o_zip_2           ? refPreOerder.item.o_zip_2       :  '';
				o_pref        = refPreOerder.item.o_pref            ? refPreOerder.item.o_pref        :  '';
				o_addr_1      = refPreOerder.item.o_addr_1          ? refPreOerder.item.o_addr_1      :  '';
				o_addr_2      = refPreOerder.item.o_addr_2          ? refPreOerder.item.o_addr_2      :  '';
				o_addr_3      = refPreOerder.item.o_addr_3          ? refPreOerder.item.o_addr_3      :  '';
				o_tel_1       = refPreOerder.item.o_tel_1           ? refPreOerder.item.o_tel_1       :  '';
				o_tel_2       = refPreOerder.item.o_tel_2           ? refPreOerder.item.o_tel_2       :  '';
				o_tel_3       = refPreOerder.item.o_tel_3           ? refPreOerder.item.o_tel_3       :  '';
				o_com_name    = refPreOerder.item.o_com_name        ? refPreOerder.item.o_com_name    :  '';
				payment_type  = refPreOerder.item.payment_type      ? refPreOerder.item.payment_type  :  '';
				carriage_type = refPreOerder.item.carriage_type     ? refPreOerder.item.carriage_type :  '';
				wrapping_type = refPreOerder.item.wrapping_type     ? refPreOerder.item.wrapping_type :  '';
				coupon_type   = refPreOerder.item.coupon_type       ? refPreOerder.item.coupon_type   :  '';
				o_date_h      = refPreOerder.item.o_date_h          ? refPreOerder.item.o_date_h      :  '';
				o_date_type   = refPreOerder.item.o_date_type       ? refPreOerder.item.o_date_type   :  '';
				order_memo    = refPreOerder.item.order_memo        ? refPreOerder.item.order_memo    :  '';
				card_id       = refPreOerder.item.card_id           ? refPreOerder.item.card_id       :  '';
				card_name     = refPreOerder.item.card_name         ? refPreOerder.item.card_name     :  '';
				//console.log('card_id:' + card_id);
				//console.log('card_name:' + card_name);

				//指定されているクレジットカード情報があるか
				let card_check_flag = false;
				const refCard = await API.StripeCard.GetAll();
				if(refCard.items){
					for(const item of refCard.items){
						const ref_card_id = item.card_id;
						if(ref_card_id === card_id){
							card_check_flag = true;
							break;
						}
					}
				}
				//存在しない場合、カードを選び直す
				if(!card_check_flag){
					card_id   = '';
					card_name = '';
					if(refCard.items){
						for(const item of refCard.items){
							const use_flag = item.use_flag ? true : false;
							if(use_flag){
								card_id   = item.card_id;
								card_name = item.card_brand + ' ' + item.last_4;
								break;
							}
						}
					}
				}
			}

			//カートデータの確認
			const refCart = await API.Cart.Check();
			console.log(refCart);

			//エラー処理
			if(refCart.error){
				this.setState({
					ErrMsg     : refCart.error + '<br/>',
					isLoading  : false,
				 });
				 return(0);
			}

			if(refCart.items === null){
				//カートにデータがない場合
				this.props.history.push('/cart/');
				return(0);
			}
			
			//-------------------------------------------------------2020.12.08-------------------------------------------------------
			//カートの中身をチェック　商品マスタにないか、在庫が
			let cartData = [];
			let cart_err_items = [];
			let total_price = 0;
			for(const item of refCart.items){
				const code  = item.code;
				const name  = item.name;
				const piece = item.piece;
				const price = item.price;
				const error = item.error;
				let flag    = false;
				if(error){
					cart_err_items.push({
						name : name,
						code : code,
						error: error,
					});
					flag = true;
				}
				else{
					const ref   = await API.Item.Get(SiteFlag.en, code);
					if(ref.item === null){
						cart_err_items.push({
							name : name,
							code : code,
							error: '該当の商品が見当たりません。こちらの商品は現在ご購入いただけません。カートから削除してください。',
						});
						flag = true;
					}
				}
				if(flag === false){
					total_price = total_price + (Number(piece) * Number(price));
					cartData.push(item);
				}
			}
			console.log(cart_err_items);
			
			//各項目取得
			//const cartData      = refCart.items;
			//const total_price   = refCart.total_price;
			//-------------------------------------------------------2020.12.08-------------------------------------------------------

			//送料の設定
			let carriage = 0;
			if(carriage_type === 'ゆうパケット'){
				carriage = carriage_1;
			}
			else if(carriage_type === '宅急便'){
				if(o_pref === '北海道' || o_pref === '沖縄県'){
					carriage = carriage_3;
				}
				else{
					carriage = carriage_2;
				}
			}

			//ゆうパケット選択時の制限
			if(carriage_type === 'ゆうパケット'){

				//ゆうパケットの場合日付時間指定ができない
				isODate  = false;
				o_date_h = '指定なし';

				//代金引換えを選択している場合は、ゆうパケットは選べない。
				if(this.state.payment_type === '代金引換'){
					carriage_type = '';
				}

				//#■「メール便３個まで（icon_4）」と「メール便１０個まで（icon_6）」の2種類のグラフがあります。
				//#　メール便を選択できる条件は下記の通り
				//#１．「メール便３個（icon_4）」が3個以下の場合、
				//#　　なおかつ、「メール便１０個（icon_6）」が10個以下の場合、
				//#　　なおかつ、「メール便３個（icon_4）」と「メール便１０個（icon_6）」を併せて10個以下の場合
				//#■宅急便しか選べない条件
				//#２．上記、１以外の場合
				//#icon_4とicon_6が同時にチェックされてる場合はicon_4が優先される。
				let piece_total_icon_4 = 0;
				let piece_total_icon_6 = 0;
				let piece_total_etc = 0;
				for(const a of cartData){
					const code  = a.code;
					const piece = a.piece;
					const ref = await API.Item.Get(SiteFlag.en, code);
					if(ref.item === null){
						this.setState({
							ErrMsg      : 'カートにある商品の詳細情報が読み取れません。(1)',
							isLoading   : false,
						});
						return(0);
					}
					const flag_4 = ref.item.flag_4;
					const flag_6 = ref.item.flag_6;
					//console.log(flag_4);
					//console.log(flag_6);
					if(flag_4){
						piece_total_icon_4 = Number(piece_total_icon_4) + Number(piece);
					}
					else if(flag_6){
						piece_total_icon_6 = Number(piece_total_icon_6) + Number(piece);
					}
					else{
						piece_total_etc = Number(piece_total_etc) + Number(piece);
					}
				}
				let item_icon_flag = false;
				if( piece_total_etc <= 0 && piece_total_icon_6 <= 10 && piece_total_icon_4 <= 3 && (piece_total_icon_6 + piece_total_icon_4) <=10 ){
					item_icon_flag = true;
				}
				//console.log(item_icon_flag);
				if(item_icon_flag === false){
					carriage_type = '';
				}
			}
			else if(!o_date_h){
				o_date_h = '指定なし';

			}

			//送料無料フラグ対応
			let carriage_free_flag_8 = false;
			for(const a of cartData){
				const code  = a.code;
				const ref = await API.Item.Get(SiteFlag.en, code);
				if(ref.item === null){
					this.setState({
						ErrMsg      : 'カートにある商品の詳細情報が読み取れません。(2)',
						isLoading   : false,
					});
					return(0);
				}
				const flag_8 = ref.item.flag_8;
				if(flag_8){
					carriage_free_flag_8 = true;
				}
			}
			if(carriage_free_flag_8 === true){
				if (this.state.o_pref === '北海道' || this.state.o_pref === '沖縄県'){
					//carriage = Number(this.state.carriage_3 - this.state.carriage_2);
					carriage = 0;
				}
				else{
					carriage = 0;
				}

			}

			//送料無料
			let carriage_free_result = false;
			//console.log('carriage_free_flag:' + carriage_free_flag);
			if(carriage_free_flag){
				//購入金額が指定以上か
				//console.log('total_price:' + total_price);
				//console.log('carriage_free_price:' + carriage_free_price);
				if(carriage_free_price <= total_price){
					//会員限定か全体か
					//console.log('carriage_free_ec_user_only_flag:' + carriage_free_ec_user_only_flag);
					//リピーター情報
					const history_order = await API.Order.GetAll();
					const history_flag = (history_order.items ? (history_order.items.length ? true : false) : false );
					//console.log('carriage_free_order_history_kbn:' + carriage_free_order_history_kbn);
					//console.log('history_flag:' + history_flag);
					//リピーター区分関係なし
					if(carriage_free_order_history_kbn === 'リピーター' && history_flag === true){
						carriage_free_result = true;
						carriage = 0;
						//console.log(1);
					}
					else if(carriage_free_order_history_kbn === '初回購入' && history_flag === false){
						carriage_free_result = true;
						carriage = 0;
						//console.log(2);
					}
					else if(carriage_free_order_history_kbn === ''){
						carriage_free_result = true;
						carriage = 0;
						//console.log(3);
					}
				}
			}
			//console.log('carriage_free_result:' + carriage_free_result);

			//送料の設定
			let cashdeli = 0;
			if(payment_type === '代金引換'){
				cashdeli = cashdeli_1;
			}
			else if(payment_type === '後払い決済'){
				cashdeli = cashdeli_2;
			}

			//ラッピングの設定
			let wrapping = 0;
			//if(wrapping_type === '有料ラッピングする' || wrapping_type === '季節のラッピング（A）'|| wrapping_type === '季節のラッピング（B）'){
			if(wrapping_type === '通常ラッピング＋カード【1】' || wrapping_type === '通常ラッピング＋カード【2】' || wrapping_type === '通常ラッピング＋カード【3】' || wrapping_type === '季節のラッピング（A）＋カード【1】' || wrapping_type === '季節のラッピング（A）＋カード【2】' || wrapping_type === '季節のラッピング（A）＋カード【3】' || wrapping_type === '季節のラッピング（B）＋カード【1】' || wrapping_type === '季節のラッピング（B）＋カード【2】' || wrapping_type === '季節のラッピング（B）＋カード【3】'){

				wrapping = wrapping_1;
			}
			else{
				wrapping_type = 'しない';
			}

			//クーポンの設定
			let coupon = 0;
			if(coupon_type === '会員限定クーポン500円割引'){

				//以前の利用を確認
				const refCoupon = await API.Coupon.Get(coupon_type);
				if(refCoupon.item !== null){
					if(refCoupon.item.coupon){
						coupon_type = '';
						coupon = 0;
					}
				}
				if(total_price < 2000){
					coupon_type = '';
					coupon = 0;
				}
				else{
					coupon = -500;
				}
			}

			//カード情報を取得
			/* 通常使うクレジットカード機能に統合するため廃止
			if(card_id){
				const refCard = await API.StripeCard.Get(card_id);
				if(!(refCard.item) && payment_type === 'クレジットカード決済'){
					payment_type = '';
					card_id   = '';
					card_name = '';
				}
			}
			*/

			//合計金額の計算
			const payment_price = this.calcPaymentPrice({
									total_price : total_price,
									carriage    : carriage,
									cashdeli    : cashdeli,
									wrapping    : wrapping,
									coupon      : coupon,
								   });

			//今日の日付＋date_diff
			const dateObj = new Date();
			dateObj.setDate(dateObj.getDate() + Number(date_diff));
			const startDate = dateObj.getFullYear() + '-' + ("0"+(dateObj.getMonth()+1)).slice(-2) + '-' + ("0"+dateObj.getDate()).slice(-2);

			//3ヶ月後
			dateObj.setMonth(dateObj.getMonth() + 2);
			const endDate = dateObj.getFullYear() + '-' + ("0"+(dateObj.getMonth()+1)).slice(-2) + '-' + ("0"+dateObj.getDate()).slice(-2);

			//表示更新
			this.setState({
				isLoading     : false,
				isODate       : isODate,
				carriage_free_result            : carriage_free_result,
				carriage_free_flag              : carriage_free_flag,
				carriage_free_price             : carriage_free_price,
				carriage_free_ec_user_only_flag : carriage_free_ec_user_only_flag,
				carriage_free_order_history_kbn : carriage_free_order_history_kbn,
				carriage_1    : carriage_1    ,
				carriage_2    : carriage_2    ,
				carriage_3    : carriage_3    ,
				cashdeli_1    : cashdeli_1    ,
				cashdeli_2    : cashdeli_2    ,
				wrapping_1    : wrapping_1    ,
				date_diff     : date_diff     ,
				email         : email         ,
				name_1        : name_1        ,
				name_2        : name_2        ,
				name_furi_1   : name_furi_1   ,
				name_furi_2   : name_furi_2   ,
				zip_1         : zip_1         ,
				zip_2         : zip_2         ,
				pref          : pref          ,
				addr_1        : addr_1        ,
				addr_2        : addr_2        ,
				addr_3        : addr_3        ,
				tel_1         : tel_1         ,
				tel_2         : tel_2         ,
				tel_3         : tel_3         ,
				addressid     : addressid     ,
				o_name_1      : o_name_1      ,
				o_name_2      : o_name_2      ,
				o_name_furi_1 : o_name_furi_1 ,
				o_name_furi_2 : o_name_furi_2 ,
				o_zip_1       : o_zip_1       ,
				o_zip_2       : o_zip_2       ,
				o_pref        : o_pref        ,
				o_addr_1      : o_addr_1      ,
				o_addr_2      : o_addr_2      ,
				o_addr_3      : o_addr_3      ,
				o_tel_1       : o_tel_1       ,
				o_tel_2       : o_tel_2       ,
				o_tel_3       : o_tel_3       ,
				o_com_name    : o_com_name    ,
				payment_type  : payment_type  ,
				wrapping_type : wrapping_type ,
				coupon_type   : coupon_type   ,
				cashdeli      : cashdeli      ,
				wrapping      : wrapping      ,
				coupon        : coupon        ,
				carriage_type : carriage_type ,
				carriage      : carriage      ,
				total_price   : total_price   ,
				payment_price : payment_price ,
				cartData      : cartData      ,
				startDate     : startDate     ,
				endDate       : endDate       ,
				o_date_type   : o_date_type   ,
				o_date_h      : o_date_h      ,
				order_memo    : order_memo    ,
				card_id       : card_id       ,
				card_name     : card_name     ,
				cart_err_items : cart_err_items,
			});

			//クーポン更新
			this.selectCoupon(coupon_type, coupon);

		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLoading : false,
			 });
		}
		//フォームリセット
		//document.getElementById("form").reset();
	}
	//アドレスの設定
	async setAddress(addressid, o_name_1, o_name_2, o_name_furi_1, o_name_furi_2, o_zip_1, o_zip_2, o_pref, o_addr_1, o_addr_2, o_addr_3, o_tel_1, o_tel_2, o_tel_3, o_com_name){

		try{

			//console.log(addressid);

			this.setState({
				isValidated   : true,
				isLockForm    : true,
			});

			//データを作る
			const orderData = {
				flag          : this.state.flag,
				addressid     : addressid,
				o_name_1      : o_name_1       ,
				o_name_2      : o_name_2       ,
				o_name_furi_1 : o_name_furi_1  ,
				o_name_furi_2 : o_name_furi_2  ,
				o_zip_1       : o_zip_1        ,
				o_zip_2       : o_zip_2        ,
				o_pref        : o_pref         ,
				o_addr_1      : o_addr_1       ,
				o_addr_2      : o_addr_2       ,
				o_addr_3      : o_addr_3 ? o_addr_3 : null,
				o_tel_1       : o_tel_1        ,
				o_tel_2       : o_tel_2        ,
				o_tel_3       : o_tel_3        ,
				o_com_name    : o_com_name ? o_com_name : null,
			};

			//仮受注データに入れる
			const ref = await API.PreOrder.Update(orderData);

			if(ref.error === null){
				//表示更新
				this.setState({
					ErrMsg        : '',
					isLockForm    : false,
					isValidated   : false,
					addressid     : addressid      ,
					o_name_1      : o_name_1       ,
					o_name_2      : o_name_2       ,
					o_name_furi_1 : o_name_furi_1  ,
					o_name_furi_2 : o_name_furi_2  ,
					o_zip_1       : o_zip_1        ,
					o_zip_2       : o_zip_2        ,
					o_pref        : o_pref         ,
					o_addr_1      : o_addr_1       ,
					o_addr_2      : o_addr_2       ,
					o_addr_3      : o_addr_3       ,
					o_tel_1       : o_tel_1        ,
					o_tel_2       : o_tel_2        ,
					o_tel_3       : o_tel_3        ,
					o_com_name    : o_com_name     ,
				});
				//console.log(isODate);
			}
			else{
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}


		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLockForm  : false,
			 });
			return(0);
		}
	}
	//送料の計算
	async selectCarriage(carriage_type, carriage){

		try{

			this.setState({
				isValidated   : true,
				isLockForm    : true,
			});

			//日付指定フラグ
			let isODate  = this.state.isODate;
			let o_date_h = this.state.o_date_h;

			//ゆうパケット選択時の制限
			if(carriage_type === 'ゆうパケット'){

				//ゆうパケットの場合日付時間指定ができない
				isODate  = false;
				o_date_h = '指定なし';

				//代金引換えを選択している場合は、ゆうパケットは選べない。
				if(this.state.payment_type === '代金引換'){
					this.setState({
						ErrMsg      : 'お届け方法「代金引換」選択時に「ゆうパケット」は選択できません。<br/>',
						isLockForm  : false,
					});
					return(0);
				}

				//#■「メール便３個まで（icon_4）」と「メール便１０個まで（icon_6）」の2種類のグラフがあります。
				//#　メール便を選択できる条件は下記の通り
				//#１．「メール便３個（icon_4）」が3個以下の場合、
				//#　　なおかつ、「メール便１０個（icon_6）」が10個以下の場合、
				//#　　なおかつ、「メール便３個（icon_4）」と「メール便１０個（icon_6）」を併せて10個以下の場合
				//#■宅急便しか選べない条件
				//#２．上記、１以外の場合
				//#icon_4とicon_6が同時にチェックされてる場合はicon_4が優先される。
				let piece_total_icon_4 = 0;
				let piece_total_icon_6 = 0;
				let piece_total_etc = 0;
				for(const a of this.state.cartData){
					const code  = a.code;
					const piece = a.piece;
					const ref = await API.Item.Get(SiteFlag.en, code);
					if(ref.item === null){
						this.setState({
							ErrMsg      : 'カートにある商品の詳細情報が読み取れません。(3)',
							isLockForm  : false,
						});
						return(0);
					}
					const flag_4 = ref.item.flag_4;
					const flag_6 = ref.item.flag_6;
					//console.log(flag_4);
					//console.log(flag_6);
					if(flag_4){
						piece_total_icon_4 = Number(piece_total_icon_4) + Number(piece);
					}
					else if(flag_6){
						piece_total_icon_6 = Number(piece_total_icon_6) + Number(piece);
					}
					else{
						piece_total_etc = Number(piece_total_etc) + Number(piece);
					}
				}
				let item_icon_flag = false;
				if( piece_total_etc <= 0 && piece_total_icon_6 <= 10 && piece_total_icon_4 <= 3 && (piece_total_icon_6 + piece_total_icon_4) <=10 ){
					item_icon_flag = true;
				}
				//console.log(item_icon_flag);
				if(item_icon_flag === false){
					this.setState({
						ErrMsg      : 'お選びの商品の組み合わせでは、「ゆうパケット」は選択できません。',
						isLockForm  : false,
					});
					return(0);
				}
			}
			else if(!o_date_h){
				o_date_h = '指定なし';

			}

			//送料無料フラグ対応
			let carriage_free_flag_8 = false;
			for(const a of this.state.cartData){
				const code  = a.code;
				const ref = await API.Item.Get(SiteFlag.en, code);
				if(ref.item === null){
					this.setState({
						ErrMsg      : 'カートにある商品の詳細情報が読み取れません。(4)',
						isLockForm  : false,
					});
					return(0);
				}
				const flag_8 = ref.item.flag_8;
				//console.log(flag_8);
				if(flag_8){
					carriage_free_flag_8 = true;
				}
			}
			//console.log(carriage_free_flag_8);
			if(carriage_free_flag_8 === true){
				if (this.state.o_pref === '北海道' || this.state.o_pref === '沖縄県'){
					//carriage = Number(this.state.carriage_3 - this.state.carriage_2);
					carriage = 0;
				}
				else{
					carriage = 0;
				}

			}

			const carriage_free_flag              = this.state.carriage_free_flag;
			const carriage_free_price             = this.state.carriage_free_price;
			//const carriage_free_ec_user_only_flag = this.state.carriage_free_ec_user_only_flag;
			const carriage_free_order_history_kbn = this.state.carriage_free_order_history_kbn;
			const total_price                     = this.state.total_price;

			//送料無料
			let carriage_free_result = false;
			//console.log('carriage_free_flag:' + carriage_free_flag);
			if(carriage_free_flag){
				//購入金額が指定以上か
				//console.log('total_price:' + total_price);
				//console.log('carriage_free_price:' + carriage_free_price);
				if(carriage_free_price <= total_price){
					//会員限定か全体か
					//console.log('carriage_free_ec_user_only_flag:' + carriage_free_ec_user_only_flag);
					//リピーター情報
					const history_order = await API.Order.GetAll();
					const history_flag = (history_order.items ? (history_order.items.length ? true : false) : false );
					//console.log('carriage_free_order_history_kbn:' + carriage_free_order_history_kbn);
					//console.log('history_flag:' + history_flag);
					//リピーター区分関係なし
					if(carriage_free_order_history_kbn === 'リピーター' && history_flag === true){
						carriage_free_result = true;
						carriage = 0;
						//console.log(1);
					}
					else if(carriage_free_order_history_kbn === '初回購入' && history_flag === false){
						carriage_free_result = true;
						carriage = 0;
						//console.log(2);
					}
					else if(carriage_free_order_history_kbn === ''){
						carriage_free_result = true;
						carriage = 0;
						//console.log(3);
					}
				}
			}
			//console.log('carriage_free_result:' + carriage_free_result);


			//合計金額の計算
			const payment_price = this.calcPaymentPrice({carriage : carriage});

			//データを作る
			const orderData = {
				flag          : this.state.flag,
				carriage_type : carriage_type  ,
				isODate       : isODate        ,
				o_date_h      : o_date_h       ,
			};

			//仮受注データに入れる
			const ref = await API.PreOrder.Update(orderData);

			if(ref.error === null){
				//表示更新
				this.setState({
					ErrMsg        : '',
					isLockForm    : false,
					isValidated   : false,
					carriage_type : carriage_type,
					carriage      : carriage,
					payment_price : payment_price,
					isODate       : isODate,
					o_date_h      : o_date_h,
					carriage_free_result : carriage_free_result,
				});
				//console.log(isODate);

				//強制的にラッピングを外す
				if(carriage_type === 'ゆうパケット'){
					this.selectWrapping('しない', 0);
				}
			}
			else{
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}


		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLockForm  : false,
			 });
			return(0);
		}
		try{
			//フォームリセット
			document.getElementById("form").reset();
		}
		catch(error) {
			console.log(error);
		}
	}
	//決済手数料の計算
	async selectPayment(payment_type, cashdeli, card_id){

		try{

			this.setState({
				isValidated   : true,
				isLockForm    : true,
			});

			//代金引換えを選択している場合は、ゆうパケットは選べない。
			if(payment_type === '代金引換'){
				if(this.state.carriage_type === 'ゆうパケット'){
					this.setState({
						ErrMsg      : 'お支払い方法「ゆうパケット」選択時には「代金引換」は選択できません。<br/>',
						isLockForm  : false,
					});
					return(0);
				}
			}

			//合計金額の計算
			const payment_price = this.calcPaymentPrice({cashdeli : cashdeli});

			//カード名
			let card_name = '';

			//カード情報を取得
			if(card_id){
				const refCard = await API.StripeCard.Get(card_id);
				card_name = refCard.item.card_brand + ' ' + refCard.item.last_4;
			}
			//console.log(card_name);

			//データを作る
			const orderData = {
				flag          : this.state.flag,
				payment_type  : payment_type  ,
				card_id       : card_id   ? card_id   : (this.state.card_id   ? this.state.card_id   : null),
				card_name     : card_name ? card_name : (this.state.card_name ? this.state.card_name : null),
			};

			//仮受注データに入れる
			const ref = await API.PreOrder.Update(orderData);

			if(ref.error === null){
				//表示更新
				this.setState({
					ErrMsg        : '',
					isLockForm    : false,
					isValidated   : false,
					payment_type  : payment_type,
					cashdeli      : cashdeli,
					payment_price : payment_price,
					card_id       : card_id   ? card_id   : this.state.card_id,
					card_name     : card_name ? card_name : this.state.card_name,
				});

			}
			else{
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLockForm  : false,
			 });
			return(0);
		}
	}
	//配送日の選択
	async selectODate(event, isODate, value){

		try{

			this.setState({
				isValidated   : true,
				isLockForm    : true,
			});

			let o_date_type = this.state.o_date_type;
			if(isODate){
				if(event.target.name === 'o_date_type'){

					o_date_type = event.target.value;
				}
				else{
					o_date_type = value;
				}

				//日付オブジェクトにする
				const o_date_type_time = (new Date(o_date_type)).getTime();

				//開始日付
				const startDate_time = (new Date(this.state.startDate)).getTime();

				//終了日付
				const endDate_time = (new Date(this.state.endDate)).getTime();

				if(startDate_time > o_date_type_time || endDate_time < o_date_type_time){
					this.setState({
						ErrMsg      : '入力された日付は指定出来ません。<br/>',
						isLockForm  : false,
					});
					return(0);
				}

			}
			else{
				o_date_type = null;
			}
			//データを作る
			const orderData = {
				flag          : this.state.flag,
				isODate       : isODate        ,
				o_date_type   : o_date_type    ,
			};

			//仮受注データに入れる
			const ref = await API.PreOrder.Update(orderData);

			if(ref.error === null){
				//表示更新
				this.setState({
					ErrMsg        : '',
					isLockForm    : false,
					isValidated   : false,
					isODate       : isODate,
					o_date_type   : o_date_type,
				});
			}
			else{
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}

		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLockForm  : false,
			 });
			return(0);
		}
	}
	//配送日の選択
	async selectODateH(event){

		try{

			this.setState({
				isValidated   : true,
				isLockForm    : true,
			});

			let o_date_h = this.state.o_date_h;
			if(event.target.name === 'o_date_h'){
				o_date_h = event.target.value;
			}

			//データを作る
			const orderData = {
				flag          : this.state.flag,
				o_date_h      : o_date_h    ,
			};

			//仮受注データに入れる
			const ref = await API.PreOrder.Update(orderData);

			if(ref.error === null){
				//表示更新
				this.setState({
					ErrMsg        : '',
					isLockForm    : false,
					isValidated   : false,
					o_date_h      : o_date_h,
				});
			}
			else{
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}
		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLockForm  : false,
			 });
			return(0);
		}
	}
	//ラッピングの計算
	async selectWrapping(wrapping_type, wrapping){

		try{

			this.setState({
				isValidated   : true,
				isLockForm    : true,
			});

			//合計金額の計算
			const payment_price = this.calcPaymentPrice({wrapping : wrapping});

			//データを作る
			const orderData = {
				flag          : this.state.flag,
				wrapping_type : wrapping_type  ,
			};

			//仮受注データに入れる
			const ref = await API.PreOrder.Update(orderData);

			if(ref.error === null){
				//表示更新
				this.setState({
					ErrMsg        : '',
					isLockForm    : false,
					isValidated   : false,
					wrapping_type : wrapping_type,
					wrapping      : wrapping,
					payment_price : payment_price,
				});
			}
			else{
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg      : err_msg + '<br/>',
				isLockForm  : false,
			 });
			return(0);
		}
	}
	//クーポンの計算
	async selectCoupon(coupon_type, coupon){

		try{

			this.setState({
				isValidated   : true,
				isLockForm    : true,
			});

			//クーポン確認
			/*
			if(!this.state.coupon_type){
				//エラー処理
				this.setState({
					ErrMsg     : '※すでにクーポンを使用しています。<br/>',
					isLockForm : false,
				 });
				 return(0);
			}
			*/

			if(coupon_type){
				//会員のみ
				if(!this.state.cognitoid){
					//エラー処理
					this.setState({
						ErrMsg     : '※会員の方だけ使用できます。<br/>',
						isLockForm : false,
					 });
					 return(0);
				}

				//金額確認
				if(this.state.total_price < 2000){
					//エラー処理
					this.setState({
						ErrMsg     : '※商品合計金額が2000円以上の方のみ使用できます。<br/>',
						isLockForm : false,
					 });
					 return(0);
				}

				//以前の利用を確認
				const refCoupon = await API.Coupon.Get(coupon_type);
				if(refCoupon.item !== null){
					if(refCoupon.item.coupon){
						//エラー処理
						this.setState({
							ErrMsg     : '※「' + coupon_type + '」のクーポンは以前に利用されております。<br/>',
							isLockForm : false,
						 });
						 return(0);
					}
				}
			}

			//合計金額の計算
			const payment_price = this.calcPaymentPrice({coupon : coupon});

			//データを作る
			const orderData = {
				flag          : this.state.flag,
				coupon_type   : coupon_type ? coupon_type : null,
			};

			//仮受注データに入れる
			const ref = await API.PreOrder.Update(orderData);

			if(ref.error === null){
				//表示更新
				this.setState({
					ErrMsg        : '',
					isLockForm    : false,
					isValidated   : false,
					coupon_type   : coupon_type,
					coupon        : coupon,
					payment_price : payment_price,
				});
			}
			else{
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}
		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLockForm  : false,
			 });
			return(0);
		}
	}
	//その他ご要望など
	async changeOrderMemo(event){

		if(event.target.name === 'order_memo'){
			this.setState({
				order_memo   : event.target.value,
			});
		}

	}
	//その他ご要望など
	async updateOrderMemo(){

		try{

			const order_memo = this.state.order_memo;

			//データを作る
			const orderData = {
				flag          : this.state.flag               ,
				order_memo    : order_memo ? order_memo : null,
			};

			//仮受注データに入れる
			await API.PreOrder.Update(orderData);

		}
		catch(error) {
			console.log(error);
		}

	}
	//合計金額の計算
	calcPaymentPrice(newData){
		const total_price   = Number(newData.total_price === undefined ? this.state.total_price : newData.total_price);
		const carriage      = Number(newData.carriage    === undefined ? this.state.carriage    : newData.carriage   );
		const cashdeli      = Number(newData.cashdeli    === undefined ? this.state.cashdeli    : newData.cashdeli   );
		const wrapping      = Number(newData.wrapping    === undefined ? this.state.wrapping    : newData.wrapping   );
		const coupon        = Number(newData.coupon      === undefined ? this.state.coupon      : newData.coupon     );
		const payment_price = Number(total_price + carriage + cashdeli + wrapping + coupon);
		return(payment_price);
	}
	handleReload() {
		//データの再取得
		this.setState({
			isLoading       : true,
			isAddressSelect : false,
			isCardSelect    : false,
		});
		this._getData();
	}
	handleErrorClose() {
		this.setState({
			isLockForm     : false,
			ErrMsg         : '',
			cart_err_items : [],
		});
	}
	handleBackPage(obj) {
		obj.history.push('/cart/');
	}
	lockFormClose(){
		//
	}
	async handleSubmit (event) {

		//更新処理
		try{

			event.preventDefault();
			event.stopPropagation();

			this.setState({
				isValidated   : true,
				isLockForm    : true,
			});

			//Stateから直接取得する更新項目
			const cognitoid     = this.state.cognitoid           ;

			//1ページ目で入力した情報
			//const isODate       = this.state.isODate          ;
			const email         = this.state.email            ;
			const name_1        = this.state.name_1           ;
			const name_2        = this.state.name_2           ;
			const name_furi_1   = this.state.name_furi_1      ;
			const name_furi_2   = this.state.name_furi_2      ;
			const zip_1         = this.state.zip_1            ;
			const zip_2         = this.state.zip_2            ;
			const pref          = this.state.pref             ;
			const addr_1        = this.state.addr_1           ;
			const addr_2        = this.state.addr_2           ;
			const addr_3        = this.state.addr_3           ;
			const tel_1         = this.state.tel_1            ;
			const tel_2         = this.state.tel_2            ;
			const tel_3         = this.state.tel_3            ;
			const addressid     = this.state.addressid        ;
			const o_name_1      = this.state.o_name_1         ;
			const o_name_2      = this.state.o_name_2         ;
			const o_name_furi_1 = this.state.o_name_furi_1    ;
			const o_name_furi_2 = this.state.o_name_furi_2    ;
			const o_zip_1       = this.state.o_zip_1          ;
			const o_zip_2       = this.state.o_zip_2          ;
			const o_pref        = this.state.o_pref           ;
			const o_addr_1      = this.state.o_addr_1         ;
			const o_addr_2      = this.state.o_addr_2         ;
			const o_addr_3      = this.state.o_addr_3         ;
			const o_tel_1       = this.state.o_tel_1          ;
			const o_tel_2       = this.state.o_tel_2          ;
			const o_tel_3       = this.state.o_tel_3          ;
			const o_com_name    = this.state.o_com_name       ;

			//2ページ目で入力した情報
			const payment_type  = this.state.payment_type     ;
			const carriage_type = this.state.carriage_type    ;
			const wrapping_type = this.state.wrapping_type    ;
			const coupon_type   = this.state.coupon_type      ;
			const o_date_type   = this.state.o_date_type ? this.state.o_date_type : '指定なし';
			const o_date_h      = this.state.o_date_h ? this.state.o_date_h : '指定なし';
			const order_memo    = this.state.order_memo       ;
			const card_id       = this.state.card_id       ;

			//金額の情報
			const total_price   = this.state.total_price;
			const payment_price = this.state.payment_price;
			//const carriage      = this.state.carriage;
			//const wrapping      = this.state.wrapping;
			const coupon        = this.state.coupon;
			//const cashdeli      = this.state.cashdeli;

			//カート情報
			const cartData      = this.state.cartData;

			//エラー
			let ErrMsg = '';

			//必須項目の確認（１ページ）
			if(!email)         { ErrMsg = ErrMsg + '※メールアドレスが入力されていません。<br/>'; }
			if(!name_1)        { ErrMsg = ErrMsg + '※注文者の姓が入力されていません。<br/>'; }
			if(!name_2)        { ErrMsg = ErrMsg + '※注文者の名が入力されていません。<br/>'; }
			if(!name_furi_1)   { ErrMsg = ErrMsg + '※注文者のセイが入力されていません。<br/>'; }
			if(!name_furi_2)   { ErrMsg = ErrMsg + '※注文者のメイが入力されていません。<br/>'; }
			if(!zip_1)         { ErrMsg = ErrMsg + '※注文者の郵便番号が入力されていません。<br/>'; }
			if(!zip_2)         { ErrMsg = ErrMsg + '※注文者の郵便番号が入力されていません。<br/>'; }
			if(!pref)          { ErrMsg = ErrMsg + '※注文者の都道府県が入力されていません。<br/>'; }
			if(!addr_1)        { ErrMsg = ErrMsg + '※注文者の市町村が入力されていません。<br/>'; }
			if(!addr_2)        { ErrMsg = ErrMsg + '※注文者の番地が入力されていません。<br/>'; }
			if(!tel_1)         { ErrMsg = ErrMsg + '※注文者の電話番号が入力されていません。<br/>'; }
			if(!tel_2)         { ErrMsg = ErrMsg + '※注文者の電話番号が入力されていません。<br/>'; }
			if(!tel_3)         { ErrMsg = ErrMsg + '※注文者の電話番号が入力されていません。<br/>'; }
			if(!addressid)     { ErrMsg = ErrMsg + '※お届け先が選択されていません。<br/>'; }
			if(!o_name_1)      { ErrMsg = ErrMsg + '※お届け先の姓が入力されていません。<br/>'; }
			if(!o_name_2)      { ErrMsg = ErrMsg + '※お届け先の名が入力されていません。<br/>'; }
			if(!o_name_furi_1) { ErrMsg = ErrMsg + '※お届け先のセイが入力されていません。<br/>'; }
			if(!o_name_furi_2) { ErrMsg = ErrMsg + '※お届け先のメイが入力されていません。<br/>'; }
			if(!o_zip_1)       { ErrMsg = ErrMsg + '※お届け先の郵便番号が入力されていません。<br/>'; }
			if(!o_zip_2)       { ErrMsg = ErrMsg + '※お届け先の郵便番号が入力されていません。<br/>'; }
			if(!o_pref)        { ErrMsg = ErrMsg + '※お届け先の都道府県が入力されていません。<br/>'; }
			if(!o_addr_1)      { ErrMsg = ErrMsg + '※お届け先の市町村が入力されていません。<br/>'; }
			if(!o_addr_2)      { ErrMsg = ErrMsg + '※お届け先の番地が入力されていません。<br/>'; }
			if(!o_tel_1)       { ErrMsg = ErrMsg + '※お届け先の電話番号が入力されていません。<br/>'; }
			if(!o_tel_2)       { ErrMsg = ErrMsg + '※お届け先の電話番号が入力されていません。<br/>'; }
			if(!o_tel_3)       { ErrMsg = ErrMsg + '※お届け先の電話番号が入力されていません。<br/>'; }

			//必須項目の確認（２ページ）
			if(!payment_type)         { ErrMsg = ErrMsg + '※決済方法選択されていません。<br/>'; }
			if(!carriage_type)        { ErrMsg = ErrMsg + '※お届け方法が選択されていません。<br/>'; }
			if(!wrapping_type)        { ErrMsg = ErrMsg + '※ラッピング方法が選択されていません。<br/>'; }
			//if(!o_date_type)          { ErrMsg = ErrMsg + '※お届け日時指定が選択されていません。<br/>'; }

			//ゆうパケット選択時の制限
			if(carriage_type === 'ゆうパケット'){

				//代金引換えを選択している場合は、ゆうパケットは選べない。
				if(this.state.payment_type === '代金引換'){
					this.setState({
						ErrMsg      : 'お届け方法「代金引換」選択時に「ゆうパケット」は選択できません。<br/>',
						isLockForm  : false,
					});
					return(0);
				}

				//#■「メール便３個まで（icon_4）」と「メール便１０個まで（icon_6）」の2種類のグラフがあります。
				//#　メール便を選択できる条件は下記の通り
				//#１．「メール便３個（icon_4）」が3個以下の場合、
				//#　　なおかつ、「メール便１０個（icon_6）」が10個以下の場合、
				//#　　なおかつ、「メール便３個（icon_4）」と「メール便１０個（icon_6）」を併せて10個以下の場合
				//#■宅急便しか選べない条件
				//#２．上記、１以外の場合
				//#icon_4とicon_6が同時にチェックされてる場合はicon_4が優先される。
				let piece_total_icon_4 = 0;
				let piece_total_icon_6 = 0;
				let piece_total_etc = 0;
				for(const a of cartData){
					const code  = a.code;
					const piece = a.piece;
					const ref = await API.Item.Get(SiteFlag.en, code);
					if(ref.item === null){
						this.setState({
							ErrMsg      : 'カートにある商品の詳細情報が読み取れません。(5)',
							isLockForm  : false,
						});
						return(0);
					}
					const flag_4 = ref.item.flag_4;
					const flag_6 = ref.item.flag_6;
					//console.log(flag_4);
					//console.log(flag_6);
					if(flag_4){
						piece_total_icon_4 = Number(piece_total_icon_4) + Number(piece);
					}
					else if(flag_6){
						piece_total_icon_6 = Number(piece_total_icon_6) + Number(piece);
					}
					else{
						piece_total_etc = Number(piece_total_etc) + Number(piece);
					}
				}
				let item_icon_flag = false;
				if( piece_total_etc <= 0 && piece_total_icon_6 <= 10 && piece_total_icon_4 <= 3 && (piece_total_icon_6 + piece_total_icon_4) <=10 ){
					item_icon_flag = true;
				}
				//console.log(item_icon_flag);
				if(item_icon_flag === false){
					this.setState({
						ErrMsg      : 'お選びの商品の組み合わせでは、「ゆうパケット」は選択できません。',
						isLockForm  : false,
					});
					return(0);
				}
			}

			//クレジットカードの場合の確認
			if(payment_type === 'クレジットカード決済'){
				if(!card_id)       { ErrMsg = ErrMsg + '※入力されたクレジットカード情報がありません。<br/>'; }
			}

			//クーポンの確認
			if(!cognitoid){
				if(coupon)           { ErrMsg = ErrMsg + '※会員登録、ログインしていただきますとクーポンをご利用いただけます。<br/>'; }
			}

			//金額の確認
			if(!total_price)          { ErrMsg = ErrMsg + '※商品合計がありません。<br/>'; }
			if(!payment_price)        { ErrMsg = ErrMsg + '※総合計金額がありません。<br/>'; }

			//カートの確認
			if(cartData.length === 0) { ErrMsg = ErrMsg + '※カートの中身が空です。<br/>'; }

			//エラー表示
			if(ErrMsg){
				this.setState({
					ErrMsg     : ErrMsg,
					isLockForm : false,
				});
				return(0);
			}

			let cart_items = [];
			for(const item of cartData){
				const code  = item.code;
				const piece = item.piece;
				cart_items.push({
					code  : code,
					piece : piece,
				});
			}

			//データを作る
			const orderData = {
				flag          : SiteFlag.order                         ,
				payment_type  : payment_type  ? payment_type  : null,
				carriage_type : carriage_type ? carriage_type : null,
				wrapping_type : wrapping_type ? wrapping_type : null,
				coupon_type   : coupon_type   ? coupon_type   : null,
				email         : email         ? email         : null,
				name_1        : name_1        ? name_1        : null,
				name_2        : name_2        ? name_2        : null,
				name_furi_1   : name_furi_1   ? name_furi_1   : null,
				name_furi_2   : name_furi_2   ? name_furi_2   : null,
				zip_1         : zip_1         ? zip_1         : null,
				zip_2         : zip_2         ? zip_2         : null,
				pref          : pref          ? pref          : null,
				addr_1        : addr_1        ? addr_1        : null,
				addr_2        : addr_2        ? addr_2        : null,
				addr_3        : addr_3        ? addr_3        : null,
				tel_1         : tel_1         ? tel_1         : null,
				tel_2         : tel_2         ? tel_2         : null,
				tel_3         : tel_3         ? tel_3         : null,
				addressid     : addressid     ? addressid     : null,
				o_name_1      : o_name_1      ? o_name_1      : null,
				o_name_2      : o_name_2      ? o_name_2      : null,
				o_name_furi_1 : o_name_furi_1 ? o_name_furi_1 : null,
				o_name_furi_2 : o_name_furi_2 ? o_name_furi_2 : null,
				o_zip_1       : o_zip_1       ? o_zip_1       : null,
				o_zip_2       : o_zip_2       ? o_zip_2       : null,
				o_pref        : o_pref        ? o_pref        : null,
				o_addr_1      : o_addr_1      ? o_addr_1      : null,
				o_addr_2      : o_addr_2      ? o_addr_2      : null,
				o_addr_3      : o_addr_3      ? o_addr_3      : null,
				o_tel_1       : o_tel_1       ? o_tel_1       : null,
				o_tel_2       : o_tel_2       ? o_tel_2       : null,
				o_tel_3       : o_tel_3       ? o_tel_3       : null,
				o_com_name    : o_com_name    ? o_com_name    : null,
				o_date_type   : o_date_type   ? o_date_type   : null,
				o_date_h      : o_date_h      ? o_date_h      : null,
				order_memo    : order_memo    ? order_memo    : null,
				order_items   : cart_items    ? cart_items    : [],
				card_id       : card_id       ? card_id       : null,
			};
			//console.log(orderData);

			//受注データに入れる
			const ref = await API.Order.CommitV2(orderData);
			const orderno = ref.orderno;

			if(ref.error === null && orderno){
				//次のページ
				this.setState({
					ErrMsg      : '',
					isLockForm  : false,
					isValidated : false,
					isLastPage  : true,
					orderno     : orderno,
				 });
			}
			else{
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}
		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLockForm : false,
			 });
			return(0);
		}
	}
	componentDidMount () {
		this._getData();
	}
	async componentWillUnmount () {
		await this.updateOrderMemo();
		//フォームリセット
		try{
			document.getElementById("form").reset();
		}
		catch(error) {
			console.log(error);
		}
	}
	render() {
		if(this.state.isLastPage){
			return (
				<LastPage {...this.state} {...this.props} />
			);
		}
		if(this.state.isAddressSelect){
			return (
				<AddressSelect selectAddress={this._selectAddress} backTop={this._backTop} {...this.state} {...this.props} />
			);
		}
		else if(this.state.isCardSelect){
			return (
				<CardSelect selectCard={this._selectCard} backTop={this._backTop} {...this.state} {...this.props} />
			);
		}
		else if(this.state.isLoading){
			return (
			<Container>
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			 </Container>
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Modal show={this.state.cart_err_items.length ? true : false} onHide={this.handleErrorClose} centered>
						<Modal.Body>
						<span className="red">エラーがありました。<br/>
							{this.state.cart_err_items.map(item => {return (<p key={item.code.toString()} style={{margin: "0px"}}>「{item.name}」{item.error}</p>);})}
							<br/></span>
							上記商品は各エラーのため購入いただけません。こちらの商品以外を注文しますか？
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
							<Button variant="secondary" onClick={()=>{ this.handleBackPage(this.props); }} type="button" className="btn btn-bk">
								戻る
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Header/>
					<main>

						<div className="head-logo sec">
							<a href="/stationary_index/">
								<Image src="/svg/logo_header.svg" />
							</a>
						</div>

						<Container>
							<div className="pan">
								<div><a href="/stationary_index/">TOP</a></div>
								<div><a href="/cart/">ショッピングカート</a></div>
							</div>
						</Container>

						<Container>
							<h1>
								SHOPPING CART
								<p>
									ショッピングカート
								</p>
							</h1>
							<p>
								<Image src="/img/step_order02.svg" className="img-fluid te mx-auto d-block" />
							</p>
							<h2>
								購入手続き
							</h2>

							<h3>
								<span>
									お支払い方法・お届け方法を選択してください
								</span>
							</h3>
							<Form id="form" noValidate validated={this.state.isValidated} onSubmit={e => this.handleSubmit(e)}>
							<Row>
								<Col sm={8}>
								<Form.Group className="option-section clearfix">
									<h4>
										ご注文者情報
									</h4>
									<p className="red font-weight-bold">※後払い決済の場合、こちらが請求書送付先となります。</p>
									<div className="float-left">
										{(() => {
											if(this.state.o_name_1){
												return(
														<div>
															<p className="font-weight-bold">{this.state.name_1} {this.state.name_2} 様</p>
															〒{this.state.zip_1}-{this.state.zip_2}<br/>
															{this.state.pref}{this.state.addr_1}{this.state.addr_2}<br/>
															{
																(
																	this.state.addr_3 === 'null' ||
																	this.state.addr_3 === null
																) ? '' : this.state.addr_3
															}
															{this.state.com_name}
															<p>TEL：{this.state.tel_1}-{this.state.tel_2}-{this.state.tel_3}</p>
														</div>
												);
											}

										})()}
									</div>

								</Form.Group>
									<Form.Group className="option-section clearfix">
										<h4>
											お届け先
										</h4>

										<div className="float-left">
											{(() => {
												if(this.state.o_name_1){
													return(
															<div>
																<p className="font-weight-bold">{this.state.o_name_1} {this.state.o_name_2} 様</p>
																〒{this.state.o_zip_1}-{this.state.o_zip_2}<br/>
																{this.state.o_pref}{this.state.o_addr_1}{this.state.o_addr_2}<br/>
																{
																	(
																		this.state.o_addr_3 === 'null' ||
																		this.state.o_addr_3 === null
																	) ? '' : this.state.o_addr_3
																}
																{this.state.o_com_name}
																<p>TEL：{this.state.o_tel_1}-{this.state.o_tel_2}-{this.state.o_tel_3}</p>
															</div>
													);
												}
												else{
													return(
														<div>
															お届け先を選択してください。
														</div>
													);
												}
											})()}
										</div>
										<div className="float-right">
											<Button type="button" size="lg" className="btn-blue" disabled={this.state.isLockForm} onClick={()=>{ this.setState({ isAddressSelect : true }); }} >
												変更
											</Button>
										</div>
									</Form.Group>

									<Form.Group className="option-section">
										<h4>
											お支払い方法 <span className="small">選択してください。</span>
										</h4>
										<div className="btn-group btn-group-toggle" data-toggle="buttons">
											<label className={'btn btn-lg flex-2 btn-option ' + (this.state.payment_type === 'クレジットカード決済' ? 'active' : '')}>
												<input type="radio" id="card"autoComplete="off" defaultChecked={this.state.payment_type === 'クレジットカード決済' ? true : false}  onClick={()=>{ this.setState({ isCardSelect: true }); }} disabled={this.state.isLockForm} />
												クレジットカード<br/>
												{this.state.card_name}
											</label>
											<label className={'btn btn-lg flex-1 btn-option ' + (this.state.payment_type === '代金引換' ? 'active' : '')}>
												<input type="radio" id="daibiki" autoComplete="off" defaultChecked={this.state.payment_type === '代金引換' ? true : false} onClick={()=>{ this.selectPayment('代金引換', this.state.cashdeli_1); }} disabled={this.state.isLockForm} />
												代金引換
												<p>
													手数料{this.state.cashdeli_1.toLocaleString()}円〜
												</p>
											</label>
											<label className={'btn btn-lg flex-1 btn-option ' + (this.state.payment_type === '後払い決済' ? 'active' : '')}>
												<input type="radio" id="gmoato" autoComplete="off" defaultChecked={this.state.payment_type === '後払い決済' ? true : false} onClick={()=>{ this.selectPayment('後払い決済', this.state.cashdeli_2); }} disabled={this.state.isLockForm} />
													後払い決済
													<p>
														手数料{this.state.cashdeli_2.toLocaleString()}円
													</p>
											</label>
										</div>

										<p className="small red">※（1）［メーカー発送］と記載のある商品をご購入、（2）ゆうパケットでお届けを指定、の場合は「代金引換」はご利用いただけません。<br/>
										※◆受注生産◆と記載のある商品をご購入の場合は「後払い決済」はご利用いただけません。<br/>
											{/* <a href="/guide/payment"><i class="fas fa-caret-right"></i> お支払い方法、手数料について詳しくはこちらをご参照ください。</a>*/}
											<a href="/guide/payment"><i className="fas fa-caret-right"></i>お支払い方法、手数料について、必ずこちらのお買い物ガイドをご確認の上ご注文ください。</a></p> 
									</Form.Group>

									<Form.Group className="option-section">
										<h4>
											お届け方法 <span className="small">選択してください。</span>
										</h4>
										<div className="btn-group btn-group-toggle" data-toggle="buttons">
											<label className={'btn btn-lg flex-1 btn-option ' + (this.state.carriage_type === 'ゆうパケット' ? 'active' : '')}>
												<input type="radio" name="carriage_kbn" id="yupae" value="1" autoComplete="off" defaultChecked={this.state.carriage_type === 'ゆうパケット' ? true : false} onClick={()=>{ this.selectCarriage('ゆうパケット', this.state.carriage_1); }} disabled={this.state.isLockForm} />
												ゆうパケット
												<p>
													送料{this.state.carriage_1.toLocaleString()}円
												</p>
											</label>
											<label className={'btn btn-lg flex-1 btn-option ' + (this.state.carriage_type === '宅急便' ? 'active' : '')}>
												<input type="radio" name="carriage_kbn" id="yamato" value="2" autoComplete="off" defaultChecked={this.state.carriage_type === '宅急便' ? true : false} onClick={()=>{ this.selectCarriage('宅急便', (this.state.o_pref === '北海道' || this.state.o_pref === '沖縄県') ? this.state.carriage_3 : this.state.carriage_2); }} disabled={this.state.isLockForm} />
												宅急便送
												<p>
													料{(this.state.o_pref === '北海道' || this.state.o_pref === '沖縄県') ? this.state.carriage_3.toLocaleString() : this.state.carriage_2.toLocaleString()}円
												</p>
											</label>
										</div>
											<p className="small red">
										※【送料無料】商品は、北海道・沖縄県へのお届けは、追加送料分660円（税込）のみ頂戴します。<br/>
									※［メーカー発送］商品は、受注生産のため、それ以外の商品とは別送となります。《送料込み》商品が含まれていても、それ以外の商品の送料は別途必要となります。<br />
										※商品サイズや点数の組み合わせによってゆうパケットはお選びいただけない場合がございます。<br/>
									※送料変更のある方へは、受注確定メールにて訂正してお伝えいたします。<br/>
									<a href="/guide/send/"><i class="fas fa-caret-right"></i> 配送方法・送料について詳しくはこちらをご参照ください。</a>
										</p>
									</Form.Group>

									<Form.Group className="option-section">
										<h4>
											お届け日時指定
										</h4>
										<div>
											<div className="custom-control custom-radio">
												<input type="radio" id="send-no" className="custom-control-input" name="o_date_flag" defaultChecked={!this.state.isODate} onClick={(e)=>{ this.selectODate(e, false); }} disabled={this.state.isLockForm || this.state.carriage_type === 'ゆうパケット'} />
												<label className="custom-control-label" value="no" htmlFor="send-no">
													日付指定しない
												</label>
											</div>
											<div className="custom-control custom-radio">
												<input type="radio" id="send-yes" className="custom-control-input" name="o_date_flag" defaultChecked={this.state.isODate} onClick={(e)=>{ this.selectODate(e, true, this.state.startDate); }} disabled={this.state.isLockForm || this.state.carriage_type === 'ゆうパケット'} />
												<label className="custom-control-label" value="yes" htmlFor="send-yes">
													日付指定する
												</label>
											</div>
										</div>
										<Row>
											<Col md={3}>
												<label>
													<i class="fas fa-caret-right"></i> お届け日
												</label>
											</Col>
											<Col md={6} mb={6}>
												<Form.Control type="date" size="lg" name="o_date_type" defaultValue={this.state.startDate} min={this.state.startDate} max={this.state.endDate} required={this.state.isODate} onChange={(e)=>{ this.selectODate(e, true); }} disabled={this.state.isLockForm || !this.state.isODate || this.state.carriage_type === 'ゆうパケット'} />
											</Col>

										</Row>
										<hr className="mt-5" />

										<Row className="mt-5">
											<Col md={3}>
												<label>
													お届け時間帯
												</label>
											</Col>
											<Col md={9} mb={3}>
												<select id="o_date_h" className="form-control form-control-lg" name="o_date_h" placeholder="時間帯" defaultValue={this.state.o_date_h} onChange={(e)=>{ this.selectODateH(e); }} disabled={this.state.isLockForm || this.state.carriage_type === 'ゆうパケット'} >
												<option value="指定なし">指定なし</option>
												<option value="午前中">午前中</option>
												<option value="14：00～16：00">14：00～16：00</option>
												<option value="16：00～18：00">16：00～18：00</option>
												<option value="18：00～20：00">18：00～20：00</option>
												<option value="19：00～21：00">19：00～21：00</option>
												</select>
											</Col>
										</Row>
										<p className="small red">
											※ゆうパケットと受注生産商品は、お届け日・時間帯の指定はできません。
										</p>
									</Form.Group>

									<Form.Group className="option-section">
										<h4>
											ラッピング
										</h4>
										<Row>
											<Col  sm={12} md={9}>

													<select id="wrap_yes" className="form-control form-control-lg" name="wrapping" placeholder="ラッピング" defaultValue={this.state.wrapping_type} onChange={(e)=>{  if(e.target.value === 'しない'){this.selectWrapping('しない', 0);}else{this.selectWrapping(e.target.value, this.state.wrapping_1); } }} disabled={this.state.isLockForm || this.state.carriage_type === 'ゆうパケット'} >
											<option value="しない">しない</option>
											<option value="通常ラッピング＋カード【1】">通常ラッピング＋カード【1】（{this.state.wrapping_1.toLocaleString()}円）</option>
											<option value="通常ラッピング＋カード【2】">通常ラッピング＋カード【2】（{this.state.wrapping_1.toLocaleString()}円）</option>{/**/}
											<option value="通常ラッピング＋カード【3】">通常ラッピング＋カード【3】（{this.state.wrapping_1.toLocaleString()}円）</option>
											{/*
											<option value="季節のラッピング（A）＋カード【1】">季節のラッピング（A）＋カード【1】（{this.state.wrapping_1.toLocaleString()}円）</option>
											<option value="季節のラッピング（A）＋カード【2】">季節のラッピング（A）＋カード【2】（{this.state.wrapping_1.toLocaleString()}円）</option>
											<option value="季節のラッピング（A）＋カード【3】">季節のラッピング（A）＋カード【3】（{this.state.wrapping_1.toLocaleString()}円）</option>
											<option value="季節のラッピング（B）＋カード【1】">季節のラッピング（B）＋カード【1】（{this.state.wrapping_1.toLocaleString()}円）</option>
											<option value="季節のラッピング（B）＋カード【2】">季節のラッピング（B）＋カード【2】（{this.state.wrapping_1.toLocaleString()}円）</option>
											<option value="季節のラッピング（B）＋カード【3】">季節のラッピング（B）＋カード【3】（{this.state.wrapping_1.toLocaleString()}円）</option>*/}
											</select>


												{/*<div className="custom-control custom-radio">
													<input type="radio" id="wrap-no" name="wrapping" className="custom-control-input" defaultChecked={this.state.wrapping_type === 'しない' ? true : false} onClick={()=>{ this.selectWrapping('しない', 0); }} disabled={this.state.isLockForm || this.state.carriage_type === 'ゆうパケット'} />
													<label className="custom-control-label" value="no" htmlFor="wrap-no">
														しない
													</label>
												</div>
												<div className="custom-control custom-radio">
													<input type="radio" id="wrap-yes" name="wrapping" className="custom-control-input" defaultChecked={this.state.wrapping_type === '有料ラッピングする' ? true : false} onClick={()=>{ this.selectWrapping('有料ラッピングする', this.state.wrapping_1); }} disabled={this.state.isLockForm || this.state.carriage_type === 'ゆうパケット'} />
													<label className="custom-control-label" value="yes" htmlFor="wrap-yes">
														有料ラッピングする（{this.state.wrapping_1.toLocaleString()}円）
													</label>
												</div>
												<div className="custom-control custom-radio">
													<input type="radio" id="wrap-yes2" name="wrapping" className="custom-control-input" defaultChecked={this.state.wrapping_type === '季節のラッピング（A）' ? true : false} onClick={()=>{ this.selectWrapping('季節のラッピング（A）', this.state.wrapping_1); }} disabled={this.state.isLockForm || this.state.carriage_type === 'ゆうパケット'} />
													<label className="custom-control-label" value="yes" htmlFor="wrap-yes2">
														季節のラッピング（A）（{this.state.wrapping_1.toLocaleString()}円）
													</label>
												</div>
												<div className="custom-control custom-radio">
													<input type="radio" id="wrap-yes3" name="wrapping" className="custom-control-input" defaultChecked={this.state.wrapping_type === '季節のラッピング（B）' ? true : false} onClick={()=>{ this.selectWrapping('季節のラッピング（B）', this.state.wrapping_1); }} disabled={this.state.isLockForm || this.state.carriage_type === 'ゆうパケット'} />
													<label className="custom-control-label" value="yes" htmlFor="wrap-yes3">
														季節のラッピング（B）（{this.state.wrapping_1.toLocaleString()}円）
													</label>
												</div>*/}
												

											</Col>
										</Row>
									</Form.Group>
									<p className="small red mb-5">
													※配送方法「宅急便」の場合のみお選びいただけます。<br/>
													※ラッピング＋メッセージカードの組み合わせをお選びください。<br/>
													※1回のご注文につき、商品をまとめて1袋に包装します。<br/>
													※ラッピング不可商品のみご注文されている場合は、ラッピングはキャンセルとさせていただきます。<br/>
													※ラッピング不可の商品が含まれる場合は、ラッピング不可商品以外の商品のみ包装いたします。<br/>
													※梱包の都合上、商品価格のラベル・タグ・印字は取ることができません。一部商品は目隠しシールで価格を隠すことは可能ですので、ご希望の方は備考欄にご記載ください。<br/>
													※無料のラッピングは承っておりません。
												</p>
									<div className=""><picture>
									<source media="(max-width: 767px)" srcSet="/img/guide/img_card_sp.jpg" />
										 <Image src="/img/guide/img_card.jpg" alt="ラッピングイメージ" fluid />
									 </picture></div>
									 {/*<div className="mt-3 mb-5">
									<picture>
 									<source media="(max-width: 767px)" srcSet="/img/guide/img_wrapping-rb_sp.png" />
 											<Image src="/img/guide/img_wrapping-rb.png" alt="季節限定ラッピング" fluid/>
 										</picture>
									</div>
									
									
									*/}


								{/*	<Form.Group className="option-section">
										<h4>
											クーポン利用
										</h4>
										<div className="btn-group btn-group-toggle" data-toggle="buttons">
											<label className={'btn btn-lg btn-option ' + (this.state.coupon_type === '会員限定クーポン500円割引' ? 'active' : '')}>
												<input type="radio" id="coupon" autoComplete="off" defaultChecked={this.state.coupon_type === '会員限定クーポン500円割引' ? true : false}  onClick={()=>{ this.selectCoupon('会員限定クーポン500円割引', -500); }} disabled={this.state.isLockForm || !this.state.cognitoid} />
												会員限定クーポンを利用する
												<p>
													500円割引
												</p>
											</label>
										</div>
									</Form.Group>*/}

									<Form.Group className="option-section clearfix">
										<h4>
											商品情報
										</h4>
										<div className="float-left">
											<table className="table table-reflow">
												<tbody>

													{
														Array.isArray(this.state.cartData) ? this.state.cartData.map( item =>{

															 const code       = item.code;
															 const name       = item.name;
															 const piece      = item.piece ? item.piece.toLocaleString() : 0;
															 const piece_type = item.piece_type;
															 const price      = item.price ? item.price.toLocaleString() : 0;

															return(
																<tr key={code}>
																	<td className="itemname-option">
																		<p className="itemimg">
																			<Image className="item-img" src={'https://images.mindwave-store.com/public/' + code + '_main.jpg'} />
																		</p>
																		<p>
																			{name}
																		</p>
																	</td>
																	<td>
																		￥{price}
																	</td>
																	<td>
																		{piece} {piece_type}
																	</td>
																</tr>
															);
														}) : null
													}
												</tbody>
											</table>
										</div>

										<div className="float-right">
											<Button type="button" size="lg" className="btn-blue" onClick={()=>{ this.props.history.push('/cart/'); }}>
												変更
											</Button>
										</div>
									</Form.Group>

									<Form.Group className="option-section">
										<h4>
											その他ご要望など
										</h4>
										<textarea rows="5" className="form-control form-control-lg" id="sonota" placeholder="在庫数の確認等はご購入前にお問い合わせください" name="order_memo" defaultValue={this.state.order_memo} onChange={(e)=>{ this.changeOrderMemo(e); }} />

									</Form.Group>
									<div className="shopcomment small"><p>
										【明細書・領収書について】<br/>
明細書・領収書は同梱しておりません。ご必要な場合は、上部の備考欄（その他ご要望など）にご希望内容をお書き添えください。メールにてPDFデータをお送りいたします。<br/>
【キャンセルについて】<br/>
お客様に少しでも早く商品をお届けする為、『受注確定メ－ル』をお客様へ送信後、すぐに出荷に取りかかります。そのため、『受注確定メ－ル』送信後のキャンセルは基本的にお受け付けできかねます。万が一、ご変更・キャンセルをご希望の場合は、『受注確定メ－ル』 を添付の上、正しいご注文内容、又はキャンセル内容を記載し、早急にご返信ください。<br/>
尚、お客様からのご連絡が出荷後であった場合（休業日・営業時間終了後も含めて）は、ご注文内容の変更、キャンセル、返品、交換はお受けできませんので、あらかじめご了承ください。<br/>
※お客様都合（注文間違い、イメージと違う等）による返品・交換は承っておりません。よくご確認・ご検討の上、ご購入くださいませ。<br/>
【受注生産商品をご注文のお客様】<br/>
商品名に［メーカー発送］と記載のある商品は《予約商品》《受注生産商品》であり、入荷次第メーカーから発送されます。<br/>
商品名に記載されている時期が発送の目安となりますが、予定数を超えた場合は、次回発送分に変更となる場合がございます。
あらかじめご理解のほど、お願い申し上げます。<br/>
※ページに記載のとおり、お客様都合での返品・交換不可となりますので、サイズ等のお間違いにご注意ください。<br/>
また、メーカー発送のため代金引換便・ラッピング・日時指定不可となりますので、ご選択されている場合はこちらで変更・削除させていただきます。<br/>
※メーカー発送品となりますため、他商品と同時注文されている場合は、別送となります。別送にかかる送料は新たに請求させていただきますのであらかじめご了承ください。最終的なご請求金額は『受注確定メ－ル』にてご案内いたします。<br/>
※メーカー発送商品を代金引換で購入された場合、ご注文はキャンセルとなります。他商品との同時注文の場合は、メーカー発送商品のみキャンセルしご注文を承ります。<br/>
※◆受注生産◆商品を後払い決済で購入された場合、ご注文はキャンセルとなります。他商品との同時注文の場合は、◆受注生産◆商品のみキャンセルしご注文を承ります。<br/>

【GMO後払い決済について】<br/>
お支払い期限請求書発行から14日以内にお支払いください。お支払い期限は請求書にも記載しております。お支払い期限を一定期間過ぎてもお支払いの確認がとれない場合、ご請求金額に回収事務手数料297円（税込）が加算されます。（最大3回、合計891円）<br/>注意事項について必ず<a href="/guide/payment">《お買い物ガイド》</a>をご一読の上、ご注文ください。</p></div>
								</Col>

								<Col sm={4} className="goukei-cotent">
									<div className="goukei">
										<h4 className="title-bk">総合計金額をご確認ください</h4>
										<div>
											<ul className="list-group">
												<li className="list-group-item d-flex justify-content-between align-items-center">
													商品合計
													<span>
														¥{this.state.total_price.toLocaleString() }
													</span>
												</li>
												<li className="list-group-item d-flex justify-content-between align-items-center">
													送料 {this.state.carriage_free_result ? '(無料対象)' : ''}
													<span>
														¥{this.state.carriage.toLocaleString()}
													</span>
												</li>
												<li className="list-group-item d-flex justify-content-between align-items-center">
													手数料
													<span>
														¥{(this.state.cashdeli + this.state.wrapping).toLocaleString()}
													</span>
												</li>
												<li className="list-group-item d-flex justify-content-between align-items-center">
													クーポン
													<span className={ this.state.coupon ? 'red' : ''}>
														¥{this.state.coupon.toLocaleString()}
													</span>
												</li>
												<li className="list-group-item d-flex justify-content-between align-items-center">
													総合計金額（税込）
													<span className="option-price">
														¥{this.state.payment_price.toLocaleString()}
													</span>
												</li>
											</ul>
										</div>
										<Button type="submit" size="lg" className="btn-pink" block>
											注文を確定する
										</Button>
									</div>
								</Col>
							</Row>
							</Form>
						</Container>
					</main>
					<Footer/>
				</div>
			);
		}
	}
}

class AddressSelect extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isAddressAdd   : false,
			ErrMsg         : '',
			addressData    : [],
		}
		this._getData             = this._getData.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
	}
	async _getData () {

		try{

			//アドレスデータを取得
			const refAddress = await API.Address.GetAll();

			//データ更新
			this.setState({
				addressData   : refAddress.items ? refAddress.items : [],
				isLoading     : false,
				isAddressAdd  : refAddress.items ? false : true,
			});

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLoading  : false,
			 });
			return(0);
		}
	}
	handleReload() {
		//データの再取得
		this._getData();
	}
	handleErrorClose() {
		this.setState({
			ErrMsg         : '',
		});
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isAddressAdd){
			return (
				<AddressAdd selectAddress={this.props.selectAddress} backTop={this._backTop} {...this.state} {...this.props} />
			);
		}
		else if(this.state.isLoading){
			return (
			<Container>
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			 </Container>
			);
		}
		else{
			return (

				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>

					<Header/>
					<main>
						<div className="head-logo sec">
							<a href="/stationary_index/">
								<Image src="/svg/logo_header.svg" />
							</a>
						</div>

						<Container>
							<div className="pan">
								<div><a href="/stationary_index/">TOP</a></div>
								<div><a href="/cart/">ショッピングカート</a></div>
							</div>
						</Container>

						<Container>
							<h1>
								SHOPPING CART
								<p>
									ショッピングカート
								</p>
							</h1>
							<p>
								<Image src="/img/step_order02.svg" className="img-fluid te mx-auto d-block" />
							</p>
							<h2>
								お届け先の選択
							</h2>

							<h3>
								<span>
									お届け先を選択してください
								</span>
							</h3>

							<Form>

								<div className="delivery-list">

								{this.state.addressData.map( item =>{

									const addressid     = item.addressid;
									const o_name_1      = item.o_name_1;
									const o_name_2      = item.o_name_2;
									const o_name_furi_1 = item.o_name_furi_1;
									const o_name_furi_2 = item.o_name_furi_2;
									const o_zip_1       = item.o_zip_1;
									const o_zip_2       = item.o_zip_2;
									const o_pref        = item.o_pref;
									const o_addr_1      = item.o_addr_1;
									const o_addr_2      = item.o_addr_2;
									const o_addr_3      = item.o_addr_3;
									const o_tel_1       = item.o_tel_1;
									const o_tel_2       = item.o_tel_2;
									const o_tel_3       = item.o_tel_3;
									const o_com_name    = item.o_com_name;
									return(
											<div key={addressid}  className="delivery-add">
												<h4>{o_name_1}　{o_name_2}　様</h4>
												<p>〒{o_zip_1}-{o_zip_2}<br/>
												{o_pref}{o_addr_1}{o_addr_2}　{o_addr_3}</p>
												<p>{o_com_name}</p>
												<p>TEL：{o_tel_1}-{o_tel_2}-{o_tel_3}</p>
												<div className="row-btn">
													<Button type="button" block className="btn-blue btn-block" onClick={(e)=>{ this.props.selectAddress(
																									addressid,
																									o_name_1,
																									o_name_2,
																									o_name_furi_1,
																									o_name_furi_2,
																									o_zip_1,
																									o_zip_2,
																									o_pref,
																									o_addr_1,
																									o_addr_2,
																									o_addr_3,
																									o_tel_1,
																									o_tel_2,
																									o_tel_3,
																									o_com_name,
																								); }}>選択</Button>
												</div>
											</div>

									);
								})}
</div>
<p className="text-center mt-5">
	<Button type="button" size="lg" className="btn-blue mr-3" onClick={()=>{ this.setState({ isAddressAdd : true }); }} >新しいお届け先を登録する</Button>
								<Button type="button" size="lg" className="btn-bk" onClick={this.props.backTop} >戻る</Button>

</p>
							</Form>
						</Container>

					</main>
					<Footer/>
				</div>
			);
		}
	}
}

class AddressAdd extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading      : true,
			isValidated    : false,
			isLockForm     : false,
			ErrMsg         : '',
		}
		this._getData             = this._getData.bind(this);
		this.handleSubmit         = this.handleSubmit.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
	}
	async _getData () {

		try{

			//データ更新
			this.setState({
				isLoading     : false,
			});

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLoading  : false,
			 });
			return(0);
		}
	}
	async handleSubmit (event) {

		//更新処理
		try{

			event.preventDefault();
			event.stopPropagation();

			this.setState({
				isValidated : true,
				isLockForm  : true,
			});

			//Formオブジェクトの確認
			const formObj     = event.currentTarget;
			if(!formObj){
				this.setState({
					ErrMsg     : '※不正なエラーが発生しました。<br/>',
				});
				return(0);
			}

			//Formオブジェクトの確認
			if(formObj.checkValidity() === false){
				this.setState({
					ErrMsg     : '※入力項目にエラーがありました。<br/>',
				});
				return(0);
			}

			//Formから直接取得する更新項目
			const o_name_1      = formObj.o_name_1.value;
			const o_name_2      = formObj.o_name_2.value;
			const o_name_furi_1 = formObj.o_name_furi_1.value;
			const o_name_furi_2 = formObj.o_name_furi_2.value;
			const o_zip_1       = formObj.o_zip_1.value;
			const o_zip_2       = formObj.o_zip_2.value;
			const o_pref        = formObj.o_pref.value;
			const o_addr_1      = formObj.o_addr_1.value;
			const o_addr_2      = formObj.o_addr_2.value;
			const o_addr_3      = formObj.o_addr_3.value;
			const o_tel_1       = formObj.o_tel_1.value;
			const o_tel_2       = formObj.o_tel_2.value;
			const o_tel_3       = formObj.o_tel_3.value;
			const o_com_name    = formObj.o_com_name.value;
			const use_flag      = formObj.use_flag.checked ? true : false;

			//通常使うフラグがある場合、その他のフラグを消す
			if(use_flag){
				//アドレスデータを取得
				const refAddress = await API.Address.GetAll();
				if(refAddress.items !== null){
					const newList = refAddress.items.filter(x => x.use_flag === true);
					for(const item of newList){
						const addressid     = item.addressid     ;
						const o_name_1      = item.o_name_1      ;
						const o_name_2      = item.o_name_2      ;
						const o_name_furi_1 = item.o_name_furi_1 ;
						const o_name_furi_2 = item.o_name_furi_2 ;
						const o_zip_1       = item.o_zip_1       ;
						const o_zip_2       = item.o_zip_2       ;
						const o_pref        = item.o_pref        ;
						const o_addr_1      = item.o_addr_1      ;
						const o_addr_2      = item.o_addr_2      ;
						const o_addr_3      = item.o_addr_3      ;
						const o_tel_1       = item.o_tel_1       ;
						const o_tel_2       = item.o_tel_2       ;
						const o_tel_3       = item.o_tel_3       ;
						const o_com_name    = item.o_com_name    ;
						await API.Address.Update(addressid,o_name_1,o_name_2,o_name_furi_1,o_name_furi_2,o_zip_1,o_zip_2,o_pref,o_addr_1,o_addr_2,o_addr_3,o_tel_1,o_tel_2,o_tel_3,o_com_name,false);
					}
				}
			}
			
			//変更
			const ref = await API.Address.Add(
							o_name_1,
							o_name_2,
							o_name_furi_1,
							o_name_furi_2,
							o_zip_1,
							o_zip_2,
							o_pref,
							o_addr_1,
							o_addr_2,
							o_addr_3,
							o_tel_1,
							o_tel_2,
							o_tel_3,
							o_com_name,
							use_flag     ,
						);
			
			if(ref.error !== null){
				//エラー処理
				const error = ref.error;
				let err_msg = '';
				if(error.errors){
					error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
				}
				else{
					err_msg = error + '<br/>';
				}
				this.setState({
					ErrMsg     : err_msg + '<br/>',
					isLockForm  : false,
				});
				return(0);
			}
			else{
				//住所セット
				this.props.selectAddress(
							ref.item.addressid,
							o_name_1,
							o_name_2,
							o_name_furi_1,
							o_name_furi_2,
							o_zip_1,
							o_zip_2,
							o_pref,
							o_addr_1,
							o_addr_2,
							o_addr_3,
							o_tel_1,
							o_tel_2,
							o_tel_3,
							o_com_name,
						);
			}
			
		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
			 });
			return(0);
		}
	}
	handleReload() {
		//データの再取得
		this._getData();
	}
	handleErrorClose() {
		this.setState({
			ErrMsg         : '',
			isLockForm     : false,
		});
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
			<Container>
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			 </Container>
			);
		}
		else{
			return (

				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>

					<Header/>
					<main>
						<div className="head-logo sec">
							<a href="/stationary_index/">
								<Image src="/svg/logo_header.svg" />
							</a>
						</div>

						<Container>
							<div className="pan">
								<div><a href="/stationary_index/">TOP</a></div>
								<div><a href="/cart/">ショッピングカート</a></div>
							</div>
						</Container>

						<Container>
							<h1>
								SHOPPING CART
								<p>
									ショッピングカート
								</p>
							</h1>
							<p>
								<Image src="/img/step_order02.svg" className="img-fluid te mx-auto d-block" />
							</p>
							<h2>
								お届け先の入力
							</h2>

							<h3>
								<span>
									お届け先を入力してください
								</span>
							</h3>

							<Form noValidate validated={this.state.isValidated} onSubmit={e => this.handleSubmit(e)}>

							<Row>
								<Col className="offset-md-1 col-md-10">
									<Card>
										<Card.Body>
											<Row>
												<Col className="col-md-6 mb-3">
													<Form.Group controlId="o_name_1">
														<Form.Label>
															姓
														</Form.Label>
														<Form.Control type="text" name="o_name_1" size="lg" required pattern=".{1,255}" defaultValue={this.state.o_name_1} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															名字を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
												<Col className="col-md-6 mb-3">
													<Form.Group controlId="o_name_2">
														<Form.Label>
															名
														</Form.Label>
														<Form.Control type="text" name="o_name_2" size="lg" required pattern=".{1,255}" defaultValue={this.state.o_name_2} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															名前を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
											</Row>
											<hr className="mb-4"/>
											<Row>
												<Col className="col-md-6 mb-3">
													<Form.Group controlId="o_name_furi_1">
														<Form.Label>
															セイ
														</Form.Label>
														<Form.Control type="text" name="o_name_furi_1" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" defaultValue={this.state.o_name_furi_1} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															名字（カタカナ）を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
												<Col className="col-md-6 mb-3">
													<Form.Group controlId="o_name_furi_2">
														<Form.Label>
															メイ
														</Form.Label>
														<Form.Control type="text" name="o_name_furi_2" size="lg" required pattern="[ァ-ヴー\s　]{1,255}" defaultValue={this.state.o_name_furi_2} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															名前（カタカナ）を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
											</Row>
											<hr className="mb-4"/>
											<p className="small red">※ハイフン位置で区切って、郵便番号1（3桁）-郵便番号2（4桁）で入力してください。</p>
											<Row>
												<Col sm={3} xs={6}>
													<Form.Group controlId="o_zip_1">
														<Form.Label>
															郵便番号1
														</Form.Label>
														<Form.Control type="text" name="o_zip_1" maxLength='3' size="lg" required pattern="\d{3}" defaultValue={this.state.o_zip_1} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															郵便番号を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
											<Col sm={3} xs={6}>
													<Form.Group controlId="o_zip_2">
														<Form.Label>
															郵便番号2
														</Form.Label>
														<Form.Control type="text" name="o_zip_2" maxLength='4' size="lg" required pattern="\d{4}" defaultValue={this.state.o_zip_2} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															郵便番号を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
											</Row>
											<Row>
												<Col md={4} mb={3}>
													<Form.Group controlId="o_pref">
														<Form.Label>
															都道府県
														</Form.Label>
														<Form.Control type="text" name="o_pref" size="lg" placeholder="" required pattern=".{2,4}" defaultValue={this.state.o_pref} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															都道府県を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
												<Col md={5} mb={3}>
													<Form.Group controlId="o_addr_1">
														<Form.Label>
															市町村
														</Form.Label>
														<Form.Control type="text" name="o_addr_1" size="lg" placeholder="" required pattern=".{1,255}" defaultValue={this.state.o_addr_1} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															市町村を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
											</Row>
											<Row>
												<Col  sm={6}>
													<Form.Group controlId="o_addr_2">
														<Form.Label>
															番地
														</Form.Label>
														<Form.Control type="text" name="o_addr_2" size="lg" placeholder="" required pattern=".{1,255}" defaultValue={this.state.o_addr_2} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															住所を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>

												<Col  sm={6}>
													<Form.Group controlId="o_addr_3">
														<Form.Label>
															アパート・マンション名
														</Form.Label>
														<Form.Control type="text" name="o_addr_3" size="lg" placeholder="" pattern=".{0,255}" defaultValue={this.state.o_addr_3} disabled={this.state.isLockForm} />
													</Form.Group>
												</Col>
											</Row>
											<hr className="mb-4"/>
											<p className="form-label">電話番号</p>
<p className="small red">※ハイフン位置で3つに区切って入力してください。（例）090　1234　5678</p>
											<Row>
												<Col>
													<Form.Group controlId="o_tel_1">
														<Form.Control type="text" name="o_tel_1" maxLength='4' size="lg" placeholder="" required pattern="\d{2,4}" defaultValue={this.state.o_tel_1} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															電話番号を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
												<Col>
													<Form.Group controlId="o_tel_2">
														<Form.Control type="text" name="o_tel_2" maxLength='4' size="lg" placeholder="" required pattern="\d{2,4}" defaultValue={this.state.o_tel_2} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															電話番号を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
												<Col>
													<Form.Group controlId="o_tel_3">
														<Form.Control type="text" name="o_tel_3" maxLength='4' size="lg" placeholder="" required pattern="\d{2,4}" defaultValue={this.state.o_tel_3} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															電話番号を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
											</Row>
											<hr className="mb-4"/>
											<Row>
												<Col>
													<Form.Group controlId="o_com_name">
														<Form.Label>
															会社名
														</Form.Label> <p className="small red">※必要な場合は入力してください。</p>
														<Form.Control type="text" name="o_com_name" size="lg" placeholder="" pattern=".{0,255}" defaultValue={this.state.o_com_name} disabled={this.state.isLockForm} />
														<Form.Control.Feedback type="invalid">
															会社名を入力してください
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
											</Row>
											<hr className="mb-4"/>
											<Row>
											<Col mb={3}>
												<Form.Group controlId="use_flag">
													<Form.Label>
														このアドレスを通常使うものに指定する
													</Form.Label>
													<Form.Check type="checkbox" name="use_flag" size="lg" placeholder="" pattern=".{0,255}" defaultValue={true} disabled={this.state.isLockForm} defaultChecked={this.state.use_flag} />
													<Form.Control.Feedback type="invalid">
														エラーがありました。
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
										</Row>
										<hr className="mb-4"/>

											<Button size="lg" className="btn-blue" type="submit" block >
												入力
											</Button>
											<Button type="button" size="lg" className="btn-bk" onClick={this.props.backTop} block >
												戻る
											</Button>
										</Card.Body>
									</Card>
								</Col>
							</Row>
							</Form>
						</Container>

					</main>
					<Footer/>
				</div>
			);
		}
	}
}

class CardSelect extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			isLoading         : true,
			isCardAdd         : false,
			isShowRemoveCard  : false,
			isShowUseFlagCard : false,
			ErrMsg            : '',
			card_id           : '',
			squareCardData    : [],
		}
		this._getData             = this._getData.bind(this);
		this.setCardNonce         = this.setCardNonce.bind(this);
		this.showRemoveCard       = this.showRemoveCard.bind(this);
		this.showUseFlagCard      = this.showUseFlagCard.bind(this);
		this.removeCard           = this.removeCard.bind(this);
		this.useFlagCard          = this.useFlagCard.bind(this);
		this.handleReload         = this.handleReload.bind(this);
		this.handleErrorClose     = this.handleErrorClose.bind(this);
		this.handleClose          = this.handleClose.bind(this);
		this.lockFormClose        = this.lockFormClose.bind(this);
	}
	async _getData () {

		try{

			//アドレスデータを取得
			const ref = await API.StripeCard.GetAll();

			//データ更新
			this.setState({
				squareCardData  : ref.items ? ref.items : [],
				isLoading       : false,
				isCardAdd       : ref.items ? false : true,
			});

		}
		catch(error) {
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLoading  : false,
			 });
			return(0);
		}
	}
	//カード取引コードの取得
	async setCardNonce(token, err){

		//エラーログ
		try{
			if(err){
				await API.StripeCard.ErrorLog(SiteFlag.order, this.props.cognitoid, this.props.email, this.props.name_1, this.props.name_2, err);
			}
		}
		catch(error) {
			//console.log(error);
		}

		try{
			//console.log(token);

			this.setState({
				isValidated : true,
				isLockForm  : true,
			});


			if(token){

				//StripeIDを設定する
				await API.StripeCard.CustomerAdd();

				//StripeカードIDを設定する
				const ref = await API.StripeCard.CardAdd(token);
				//console.log(ref);

				if(ref.error){
					this.setState({
						ErrMsg     : '※' + ref.error + '<br/>',
						isLockForm  : false,
					 });
				}
				else{
					//表示更新
					this.setState({
						isCardAdd   : false,
						isLockForm  : false,
					});
					this._getData();
				}
			}
			else{
				this.setState({
					ErrMsg     : err ? err + '<br/>' : '※クレジットカードの登録に失敗しました。<br/>※再度、入力を試してください。<br/>',
					isLockForm  : false,
				 });
			}
		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLockForm  : false,
			 });
			return(0);
		}


	}
	showRemoveCard (card_id){
		this.setState({
			card_id          : card_id,
			isShowRemoveCard : true,
		});
	}
	showUseFlagCard (card_id){
		this.setState({
			card_id           : card_id,
			isShowUseFlagCard : true,
		});
	}
	async removeCard (){
		try{

			this.setState({
				isValidated : true,
				isLockForm  : true,
				isShowRemoveCard : false,
			});

			const card_id = this.state.card_id;

			//スクエアカードIDを設定する
			await API.StripeCard.Remove(card_id);

			//表示更新
			this.setState({
				isCardAdd   : false,
				isLockForm  : false,
			});

			this._getData();
		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLockForm  : false,
			 });
			return(0);
		}

	}
	async useFlagCard (){
		try{

			this.setState({
				isValidated : true,
				isLockForm  : true,
				isShowUseFlagCard : false,
			});

			const card_id = this.state.card_id;

			//スクエアカードIDを設定する
			await API.StripeCard.UseFlag(card_id);

			//表示更新
			this.setState({
				isCardAdd   : false,
				isLockForm  : false,
			});

			this._getData();
		}
		catch(error) {
			console.log(error);
			let err_msg = '';
			if(error.errors){
				error.errors.map( data => { err_msg  = err_msg + data.message.toString() + '<br/>'; return(0); } );
			}
			else{
				err_msg = error + '<br/>';
			}
			this.setState({
				ErrMsg     : err_msg + '<br/>',
				isLockForm  : false,
			 });
			return(0);
		}

	}
	handleReload() {
		//データの再取得
		this._getData();
	}
	handleErrorClose() {
		this.setState({
			ErrMsg         : '',
		});
	}
	handleClose(){
		this.setState({
			isCardAdd           : false,
			isShowRemoveCard    : false,
			isShowUseFlagCard   : false,
		});
	}
	lockFormClose(){
		//
	}
	componentDidMount () {
		this._getData();
	}
	render() {
		if(this.state.isLoading){
			return (
			<Container>
				<Row style={{ marginTop: 200, marginBottom: 200 }}>
					<Col sm={12} className="text-center">
						<Image src="/img/loading.gif" />
					</Col>
				</Row>
			 </Container>
			);
		}
		else{
			return (
				<div>
					<Modal show={this.state.ErrMsg === '' ? false : true} onHide={this.handleErrorClose} centered>
						<Modal.Body>
							エラーがありました。<br/>
							{this.state.ErrMsg.split('<br/>').map(ErrMsg => {return (<p key={ErrMsg.toString()} style={{margin: "0px"}}>{ErrMsg}</p>);})}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={this.handleErrorClose} type="button" className="btn btn-blue">
								ＯＫ
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.isCardAdd} onHide={this.handleClose} centered aria-labelledby="CardInput" dialogClassName="modal-xl">
						<Modal.Header closeButton>
							<Modal.Title id="CardInput">
								クレジットカード情報を入力してください。
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<CardInput setCardNonce={this.setCardNonce} {...this.props} isUser={true} />
						</Modal.Body>
					</Modal>
					<Modal show={this.state.isShowRemoveCard} onHide={this.handleClose} centered aria-labelledby="CardRemove" dialogClassName="modal-xl">
						<Modal.Header closeButton>
							<Modal.Title id="CardRemove">
								本当にクレジットカード情報を削除していいですか？
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Button type="button" block className="btn-blue" onClick={this.removeCard}>はい</Button>
							<Button type="button" block className="btn-bk" onClick={this.handleClose}>いいえ</Button>
						</Modal.Body>
					</Modal>
					<Modal show={this.state.isShowUseFlagCard} onHide={this.handleClose} centered aria-labelledby="CardUseFlag" dialogClassName="modal-xl">
						<Modal.Header closeButton>
							<Modal.Title id="CardUseFlag">
								通常使うクレジットカードに指定しますか？
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Button type="button" block className="btn-blue" onClick={this.useFlagCard}>はい</Button>
							<Button type="button" block className="btn-bk" onClick={this.handleClose}>いいえ</Button>
						</Modal.Body>
					</Modal>
					<Modal show={this.state.isLockForm} onHide={this.lockFormClose} centered>
						<Modal.Body className="text-center">
							<Image src="/img/loading.gif" />　
							データを更新中です。
						</Modal.Body>
					</Modal>
					<Header/>
					<main>
						<div className="head-logo sec">
							<a href="/stationary_index/">
								<Image src="/svg/logo_header.svg" />
							</a>
						</div>

						<Container>
							<div className="pan">
								<div><a href="/stationary_index/">TOP</a></div>
								<div><a href="/cart/">ショッピングカート</a></div>
							</div>
						</Container>

						<Container>
							<h1>
								SHOPPING CART
								<p>
									ショッピングカート
								</p>
							</h1>
							<p>
								<Image src="/img/step_order02.svg" className="img-fluid te mx-auto d-block" />
							</p>
							<h2>
								クレジットカードの選択
							</h2>

							<h3>
								<span>
									クレジットカードを選択してください
								</span>
							</h3>

	 <div className="stripe-info-order">1つの入力欄に、カード番号、有効期限、CVC（セキュリティコード）を入力してください。</div>

							<Form>
									<div className="delivery-list">
								{this.state.squareCardData.map( item =>{
									const card_id         = item.card_id;
									const card_brand      = item.card_brand;
									const last_4          = item.last_4;
									const exp_month       = item.exp_month;
									const exp_year        = item.exp_year;
									const use_flag        = item.use_flag;
									return(

											<div  key={card_id} className="delivery-add">
												<h4>{card_brand} 下4ケタ：{last_4}</h4>
												<p>有効期限：{exp_month}/{exp_year}</p>
												<div className="row-btn">
													<Button type="button" block className="btn-blue" onClick={(e)=>{ this.props.selectCard(card_id); }}>選択</Button>
													<Button type="button" block className="btn-bk" onClick={(e)=>{ this.showRemoveCard(card_id); }}>削除</Button>
													<Button type="button" block className="btn-blue tujo" onClick={(e)=>{ this.showUseFlagCard(card_id); }} disabled={use_flag ? true : false}>通常使うカードに設定する</Button>
												</div>
											</div>

									);
								})}

								</div>
								　								<p className="mt-5 text-center">
								<Button type="button" size="lg" className="btn-blue mr-3" onClick={()=>{ this.setState({ isCardAdd : true }); }} >新しいクレジットカードを登録する</Button>
									<Button type="button" size="lg" className="btn-blue" onClick={this.props.backTop} >戻る</Button></p>
														</Form>

						</Container>

					</main>
					<Footer/>
				</div>
			);
		}
	}
}

class LastPage extends React.Component {
	render() {
		return (
			<div>
				<Header/>
				<main>
					<div className="head-logo sec">
						<a className="" href="/stationary_index/"><Image src="/svg/logo_header.svg" /></a>
					</div>
					<Container>
						<div className="pan">
							<div><a href="/stationary_index/">TOP</a></div>
							<div><a href="/cart/">ショッピングカート</a></div>
						</div>
					</Container>
					<Container>
						<h1>SHOPPING CART<p>ショッピングカート</p></h1>
						<p><Image src="/img/step_order03.svg" className="img-fluid te mx-auto d-block"/></p>
						<h2>購入手続き</h2>
						<h3><span>ご注文ありがとうございました</span></h3>
						<Row>
							<Col className="offset-md-1 col-md-10">
								<p>
									ご注文番号は「{this.props.orderno}」となります。
								</p>
								<p>
									ご登録いただいたメールアドレス宛にご注文内容を記載した「ご注文メール」を送信しています。（システムによる自動送信）<br/>
									24時間以上経過しても「ご注文メール」が届かない場合は、ご注文が完了していないことがございますので、お手数ではございますが、
									お電話、もしくは<a href="/contact/">お問い合わせフォーム</a>よりご連絡ください。
								</p>
								<p>万が一、ご注文内容の変更やキャンセル等をご希望の場合は、至急ご連絡をお願いいたします。</p>
								<p className="red">※商品発送後の変更やキャンセルはお受けできかねます。あらかじめご了承ください。</p>
								<div className="card mt-5">
									<h4 className="card-title"><span>各通知メールの不着について</span></h4>
									<div className="card-body">
										<p>
											プロバイダーやウィルス対策ソフトの迷惑メール対策機能により、当サイトからのメールが迷惑メールと混同され、除外されている可能性もあります。
											お問い合わせの前に、一度迷惑フォルダ等をご確認ください。
										</p>
										<p>
											特に、hotmail・Yahooメール・Gmailなどのフリーメールや携帯メールドレスへのメールが届かない場合が多くございます。
											お客様のパソコンのセキュリティソフトや、メールソフトの受信設定などが影響している場合がございますので、
											「迷惑メールフォルダ」や受信設定を一度ご確認いただけますと幸いです。
										</p>
									</div>
								</div>
								<Row>
									<Col className="col-md-6 mb-4">
										<a href="/mypage/" className="btn btn-blue btn-lg btn-block">マイページへ</a>
									</Col>
									<Col className="col-md-6 mb-4">
										<a href="/stationary_index/" className="btn btn-blue btn-lg btn-block">トップページへ</a>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</main>
				<Footer/>
			</div>
		);
	}
}


export default withAuthenticator(withRouter(Default));
